export default function () {
  return {
    list: [],
    pagination: null,
    current: null,
    applications: null,
    applicationsById: {},
    owner: null,
    taker: null
  }
}
