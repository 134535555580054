export function config (state) {
  return state.config
}

export function offerTypeId (state) {
  return state.config.stelace.instant.searchOptions.modes.offer.assetTypesIds[0]
}

export function brandTypeId (state) {
  return state.config.stelace.instant.searchOptions.modes.brand.assetTypesId
}

export function companyTypeId (state) {
  return state.config.stelace.instant.searchOptions.modes.company.assetTypesIds[0]
}

export function resumeTypeId (state) {
  return state.config.stelace.instant.searchOptions.modes.resume.assetTypesId
}

export function vdcStoryTypeId (state) {
  return state.config.custom.assetTypeIds.vdcStory
}

export function companyCategoryId (state) {
  return state.config.stelace.instant.searchOptions.modes.company.categoryId
}

export function brandCategoryId (state) {
  return state.config.stelace.instant.searchOptions.modes.brand.categoryId
}

export function offerCategoryId (state) {
  return state.config.stelace.instant.searchOptions.modes.offer.categoryId
}

export function offerCategoriesId (state) {
  return state.config.stelace.instant.searchOptions.modes.offer.categoryIds
}

export function offerDefaultCategoryId (state) {
  return state.config.stelace.instant.searchOptions.modes.offer.defaultCategoryId
}

export function isDev () {
  return process.env.STELACE_API_URL.includes('dev.api.') || process.env.STELACE_API_URL.includes('localhost')
}
