import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'
import { boot } from 'quasar/wrappers'
import BootMixins from 'src/mixins/boot'
import GlobalMixins from 'hc-core/mixins/global.js'
import { intlInit } from 'hc-core/composables/intl'
import { useSentry } from 'hc-core/composables/sentry'
import HCButton from 'hc-core/components/buttons/hc-button'
import HCBigButton from 'hc-core/components/buttons/hc-big-button'
import HCLoading from 'hc-core/components/common/hc-loading'
import AppAvatar from 'hc-core/components/common/app-avatar'
import AppContent from 'hc-core/components/common/app-content'
import AccountCard from 'hc-core/components/cards/account-card'
import RawSnippet from 'hc-core/components/snippets/raw-snippet'
import ActionTooltip from 'hc-core/components/tooltips/action-tooltip'

export default boot(async ({ app, store }) => {
  if (process.env.HC_INSTANCE === 'EC2') useSentry(app)

  // Components
  app.component('HCButton', HCButton)
  app.component('HCLoading', HCLoading)
  app.component('AppAvatar', AppAvatar)
  app.component('AppContent', AppContent)
  app.component('RawSnippet', RawSnippet)
  app.component('AccountCard', AccountCard)
  app.component('HCBigButton', HCBigButton)
  app.component('ActionTooltip', ActionTooltip)

  // Mixins
  app.mixin(BootMixins)
  app.mixin(GlobalMixins)

  // Prototypes
  app.config.globalProperties.$_ = _
  app.config.globalProperties.$m = moment
  app.config.globalProperties.$axios = axios.create()

  moment.updateLocale('fr', {
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    relativeTime: {
      future: 'in %s',
      past: 'il y a %s',
      s: 'quelques secondes',
      ss: '%d secondes',
      m: 'une minute',
      mm: '%d minutes',
      h: 'une heure',
      hh: '%d heures',
      d: 'un jour',
      dd: '%d jours',
      w: 'une semaine',
      ww: '%d semaines',
      M: 'un mois',
      MM: '%d mois',
      y: 'un an',
      yy: '%d ans'
    }
  })

  // let baseURL = `https://${process.env.STELACE_API_URL}`
  // if (process.env.STELACE_API_URL.includes('localhost')) baseURL.replace('https', 'http')
  // app.config.globalProperties.$stelaxios = axios.create({
  //   baseURL,
  //   headers: {
  //     'x-api-key': process.env.STELACE_SECRET_API_KEY
  //   }
  // })

  // Filters
  app.config.globalProperties.$filters = {
    date (val) { return new Date(val).toLocaleDateString('fr-FR') },
    stripTags (val) {
      if (val && typeof val === 'string') {
        const allowed = ((('<p><a><br><br/>' || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('')
        const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi
        return val.replace(tags, function ($0, $1) {
          return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : ''
        })
      } else return val
    },
  }

  // Intl setup
  await intlInit({ app, store })
})
