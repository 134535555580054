import axios from 'axios'
import { get } from 'lodash'

export const sendinblue = axios.create({
  baseURL: 'https://api.sendinblue.com/v3/',
  headers: {
    accept: 'application/json',
    'content-type': 'application/json',
    'api-key': process.env.SENDINBLUE_API_KEY,
  }
})

export async function getSmtpTemplates () {
  const r = await sendinblue.request({
    url: '/smtp/templates?limit=100&offset=0&sort=desc'
  })
  return get(r, 'data.templates', [])
}

export default sendinblue
