// Mixin shared between all HappyCab projects and always loaded
// For project-specific mixins, refer to mixins/boot
import { useLogger } from 'hc-core/composables/logger.js'
import { pickFirstKey } from 'hc-core/composables/misc.js'
import { $app, $vdc, $web } from 'hc-core/composables/routing.js'
import { getImageUrl, getWebpImageUrl, getCroppedImageUrl, cdnImg } from 'hc-core/mixins/aws.js'

export default {
  computed: {
    common () { return this.$store.state.common },
  },
  methods: {
    // Imports
    $app,
    $vdc,
    $web,
    cdnImg,
    useLogger,
    pickFirstKey,
    getImageUrl,
    getWebpImageUrl,
    getCroppedImageUrl,

    // CustomAttributes
    getCustomAttributeValues (customAttribute) {
      const array = []
      for (const id in this.common.customAttributesById) {
        if (this.common.customAttributesById[id].name === customAttribute) {
          this.common.customAttributesById[id].listValues.forEach(item => array.push({
            label: this.$t({ id: `customAttributes.${customAttribute}.${item}` }),
            value: item
          }))
          break
        }
      }
      return array
    },

    // Internationalization
    translationExists (string) {
      const t = this.$t({ id: string })
      return string && t && t !== string && t !== '' && t !== undefined
    },

    // Notify
    notify (message, options = {}) {
      const i18n = typeof options.i18n === 'boolean' ? options.i18n : true
      const i18nValues = options.i18nValues || {}
      const newOptions = {
        color: 'primary',
        textColor: options.textColor || 'white',
        position: 'bottom',
        timeout: 5000,
        actions: [
          { label: '✕', color: options.textColor || 'white' },
        ],
        ...options,
      }
      return this.$q.notify({
        message: i18n && this.translationExists(message) ? this.$t({ id: message }, i18nValues) : message,
        ...newOptions,
      })
    },

    notifyInfo (message, options) { return this.notify(message, { color: 'info', ...options }) },
    notifyWarning (message, options) { return this.notify(message, { color: 'warning', ...options }) },
    notifySuccess (message = 'notification.saved', options) { return this.notify(message, { color: 'positive', ...options }) },
    notifyError (message = 'notification.error', options) { return this.notify(message, { color: 'negative', ...options }) },
  },
}
