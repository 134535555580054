import { get } from 'lodash'
import stelace /*, { fetchAllResults } */ from 'hc-core/composables/stelace'

// TODO - WIP - More generic way to handle content listing and edition
export async function create (context, { attrs }) {
  return await stelace.entries.create(attrs)
}

export async function read (context, { id }) {
  return await stelace.entries.read(id)
}

export async function update (context, { id, attrs }) {
  return await stelace.entries.update(id, attrs)
}

export async function remove (context, { id }) {
  return await stelace.entries.remove(id)
}

export async function list ({ commit }, { page = 1, nbResultsPerPage = 10, orderBy = 'createdDate', order = 'desc', collection = undefined, fields = undefined, metadata = undefined, rawFilters = undefined, locale = 'fr', getResults = true, createdDate = undefined }) {
  const arr = await stelace.forward.get('/entries/advanced', {
    page,
    nbResultsPerPage,
    orderBy,
    order,
    locale,
    collection,
    fields,
    metadata,
    rawFilters,
    createdDate: createdDate && createdDate !== {} ? createdDate : undefined
  })
  commit('setEntries', { key: collection, arr })
  return getResults ? arr.results : arr
}

export async function fetchi18nContent ({ commit }, { locale }) {
  commit('content/setLocale', { locale }, { root: true })
  await import(/* webpackChunkName: 'i18n-stl-[request]' */`../../../../../lib/i18n/build/${locale}.json`).then(translations => {
    commit('content/seti18nContent', { content: translations.default }, { root: true })
  })
}

// END of WIP

// export async function list ({commit}, {name, pagination, collection, locale}) {
//   const order = !pagination.descending ? "asc" : "desc";
//   if (name === '') name = undefined
//   if (collection === '') collection = undefined
//   if (locale === '') locale = undefined
//   const res = await stelace.entries.list({
//     nbResultsPerPage: pagination.rowsPerPage,
//     page: pagination.page,
//     order: order,
//     collection: collection,
//     locale: locale,
//     orderBy: pagination.sortBy,
//     name: name
//   })
//   commit('list', res)
//   commit('setPagination', res.paginationMeta)
//   return res
// }

export async function countContent ({ commit, rootGetters, rootState }) {
  const posts = await stelace.entries.list({
    collection: 'blog'
  })
  const vdcPosts = await stelace.entries.list({
    collection: 'blog-vdc'
  })
  const emails = await stelace.entries.list({
    collection: 'email'
  })
  const pages = await stelace.entries.list({
    name: 'instant_pages'
  })
  return {
    posts: {
      label: 'Articles',
      link: 'blog',
      count: posts.paginationMeta.nbResults
    },
    vdcPosts: {
      label: 'Articles VDC',
      link: 'vdcBlog',
      count: vdcPosts.paginationMeta.nbResults
    },
    pages: {
      label: 'Pages',
      link: 'pages',
      count: pages.paginationMeta.nbResults
    },
    emails: {
      label: 'Emails',
      link: 'email',
      count: emails.paginationMeta.nbResults
    },
    boosters: {
      label: 'Articles',
      link: 'blog',
      count: 0
    },
  }
  // posts: posts.paginationMeta.nbResults,
  // pages: groupedPages.length,
  // emails: emails.paginationMeta.nbResults,
  // events: posts.paginationMeta.nbResults,
  // boosters: rootState.nav.config.custom.stripe.boosters.length
}

export async function find ({ commit }, { name, pagination, collection, metadata, locale }) {
  if (name === '') name = undefined
  if (collection === '') collection = undefined
  if (locale === '') locale = undefined
  const res = await this._vm.$stelaxios.get('/entries/advanced', {
    params: {
      nbResultsPerPage: get(pagination, 'rowsPerPage', undefined),
      page: get(pagination, 'page', undefined),
      order: !get(pagination, 'descending', false) ? 'asc' : 'desc',
      orderBy: get(pagination, 'sortBy', undefined),
      collection,
      locale,
      metadata,
      name
    }
  })
  return res.data
}

// export async function get ({ commit }, id) {
//   const res = await stelace.entries.read(id)
//   commit('set', res)
//   return res
// }

export async function add ({ commit }, object) {
  const res = await stelace.entries.create(object)
  commit('set', res)
  return res
}

// export async function remove ({commit}, id) {
//   const res = await stelace.entries.remove(id)
//   commit('set', null)
//   return res
// }

export async function edit ({ commit }, object) {
  const res = await stelace.entries.update(object.id, object.data)
  commit('set', res)
  return res
}

export async function listPages ({ commit }, { pagination }) {
  const res = await stelace.forward.get('/entries/advanced', {
    collection: 'page',
    nbResultsPerPage: get(pagination, 'rowsPerPage', undefined),
    page: get(pagination, 'page', undefined),
    order: !get(pagination, 'descending', false) ? 'asc' : 'desc',
    orderBy: get(pagination, 'sortBy', undefined),
  })
  commit('setPages', res.results)
  return res
}

export async function listSEOs ({ commit }, { pagination }) {
  const res = await stelace.forward.get('/entries/advanced', {
    collection: 'seo',
    nbResultsPerPage: get(pagination, 'rowsPerPage', undefined),
    page: get(pagination, 'page', undefined),
    order: !get(pagination, 'descending', false) ? 'asc' : 'desc',
    orderBy: get(pagination, 'sortBy', undefined),
  })
  commit('setSEOs', res.results)
  return res
}

export async function listEmails ({ commit }, { pagination }) {
  const res = await stelace.entries.list({
    collection: 'email',
    nbResultsPerPage: get(pagination, 'rowsPerPage', undefined),
    page: get(pagination, 'page', undefined),
    order: !get(pagination, 'descending', false) ? 'asc' : 'desc',
    orderBy: get(pagination, 'sortBy', undefined),
  })
  commit('setEmails', res)
  return res
}

export async function listBlog ({ commit }, { pagination }) {
  const res = await stelace.forward.get('/entries/advanced', {
    collection: 'blog',
    nbResultsPerPage: get(pagination, 'rowsPerPage', undefined),
    page: get(pagination, 'page', undefined),
    order: !get(pagination, 'descending', false) ? 'asc' : 'desc',
    orderBy: get(pagination, 'sortBy', undefined),
  })
  commit('setBlog', res)
  return res
}

export async function listVdcBlog ({ commit }, { pagination }) {
  const res = await stelace.forward.get('/entries/advanced', {
    collection: 'blog-vdc',
    nbResultsPerPage: get(pagination, 'rowsPerPage', undefined),
    page: get(pagination, 'page', undefined),
    order: !get(pagination, 'descending', false) ? 'asc' : 'desc',
    orderBy: get(pagination, 'sortBy', undefined),
  })
  commit('setVdcBlog', res)
  return res
}

export async function listVdcCapsules ({ commit }, { pagination }) {
  const res = await stelace.forward.get('/entries/advanced', {
    collection: 'capsules',
    nbResultsPerPage: get(pagination, 'rowsPerPage', undefined),
    page: get(pagination, 'page', undefined),
    order: !get(pagination, 'descending', false) ? 'asc' : 'desc',
    orderBy: get(pagination, 'sortBy', undefined),
  })
  commit('setVdcCapsules', res)
  return res
}

export async function listChangelog ({ commit }, { pagination }) {
  const res = await stelace.forward.get('/entries/advanced', {
    collection: 'changelog',
    nbResultsPerPage: get(pagination, 'rowsPerPage', undefined),
    page: get(pagination, 'page', undefined),
    order: !get(pagination, 'descending', false) ? 'asc' : 'desc',
    orderBy: get(pagination, 'sortBy', undefined),
  })
  return res.results
}

export async function listForms ({ commit }, { pagination }) {
  const res = await stelace.forward.post('entries/advanced/list', {
    collection: 'form',
    nbResultsPerPage: get(pagination, 'rowsPerPage', undefined),
    page: get(pagination, 'page', undefined),
    order: !get(pagination, 'descending', false) ? 'asc' : 'desc',
    orderBy: get(pagination, 'sortBy', undefined),
  })
  commit('setForms', res)
  return res
}

// export async function editPage ({commit}, fields) {
//   const resPages = await stelace.entries.list({
//     name: 'instant_pages'
//   })
//   const res = await stelace.entries.update(resPages[0].id, {
//     fields: fields
//   })
//   return res
// }

export async function editPost ({ commit }, { attrs, postId }) {
  const res = await stelace.entries.update(postId, attrs)
  return res
}

export async function editPage ({ commit }, { attrs, pageId }) {
  const res = await stelace.entries.update(pageId, attrs)
  return res
}

export async function editEntry ({ commit }, { attrs, entryId }) {
  const res = await stelace.entries.update(entryId, attrs)
  return res
}

export async function addPage ({ commit }, entry) {
  const resPages = await stelace.entries.list({
    name: 'instant_pages'
  })
  const fields = {}
  fields[entry.page + '.header'] = ''
  fields[entry.page + '.subheader'] = ''
  fields[entry.page + '.content'] = ''
  const res = await stelace.entries.update(resPages[0].id, {
    fields
  })
  return res
}

export async function getPage ({ commit }, slug) {
  const res = await stelace.entries.list({
    name: 'instant_pages'
  })
  const pages = groupPages(res[0].fields)
  commit('setPages', pages)
  let p = null
  for (const page of pages) {
    if (page.key === slug) {
      commit('setPage', page)
      p = page
      return
    }
  }
  return p
}

function groupPages (fields) {
  const pages = {}
  for (const key in fields) {
    const name = key.split('.')[0]
    if (pages[name] === undefined) pages[name] = {}
    pages[name][key.split('.')[1]] = fields[key]
  }

  const pagesArray = []
  for (const page in pages) {
    const pageObj = pages[page]
    pageObj.key = page
    pagesArray.push(pageObj)
  }
  return pagesArray
}

export async function s3List (context, { key = undefined }) {
  return await stelace.forward.get('/integrations/s3/list', { key })
}

export async function s3Get (context, { key = undefined }) {
  return await stelace.forward.get('/integrations/s3/get', { key })
}
