// import * as Sentry from '@sentry/vue'

export function useSentry(app) {
  // Temporarily disabled to see if it causes memory leak on EC2
  // Sentry.init({
  //   app,
  //   dsn: process.env.SENTRY_DSN,
  //   integrations: [
  //     Sentry.browserTracingIntegration(),
  //     Sentry.replayIntegration(),
  //   ],
  //   // Performance Monitoring
  //   tracesSampleRate: 1.0, //  Capture 100% of the transactions
  //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //   tracePropagationTargets: ['localhost', /^https:\/\/happycab\.fr/],
  //   // Session Replay
  //   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  //   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  //   environment: `${process.env.HC_ENV}_${process.env.HC_INSTANCE}_${process.env.HC_PLATFORM}`
  // })
}
