<template>
  <QTooltip
    ref="ActionTooltip"
    class="bg-grey-5 text-caption text-white"
    :offset="[0, 5]"
  >
    <div class="flex column items-center">
      <AppContent
        v-if="path"
        :path="path"
      />
      <template v-else>
        {{ str }}
      </template>
      <slot />
    </div>
  </QTooltip>
</template>

<script>
export default {
  props: {
    path: {
      type: String,
      default: ''
    },
    str: {
      type: String,
      default: ''
    },
  },
}
</script>
