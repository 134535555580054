export default function () {
  return {
    list: [],
    current: null,
    pagination: null,
    pages: [],
    seos: [],
    seo: [],
    blog: [],
    vdcBlog: [],
    vdcCapsules: [],
    forms: [],
    emails: [],
    article: null,
    page: null,

    i18nContent: {},
  }
}
