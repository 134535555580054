import stelace from 'hc-core/composables/stelace'

// Da is da niew wé
export async function listV2 (context, { id = undefined, page = 1, nbResultsPerPage = 10, orderBy = 'createdDate', order = 'desc', event = undefined, createdDate = undefined, updatedDate = undefined, active = undefined }) {
  return await stelace.workflows.list({
    nbResultsPerPage,
    page,
    order,
    orderBy,
    id,
    createdDate,
    updatedDate,
    event,
    active,
  })
}

export async function read (context, { id, logs = undefined }) {
  return await stelace.workflows.read(id, { logs })
}

export async function update (context, { id, attrs }) {
  return await stelace.workflows.update(id, attrs)
}

// Old way
export async function list ({ commit }) {
  const res = await stelace.workflows.list({
    nbResultsPerPage: 100
  })
  commit('list', res)
  return res
}

export async function listProd ({ commit }) {
  const axios = require('axios')
  const prodInstance = axios.create({
    baseURL: 'https://api.happycab.fr',
    headers: {
      'x-api-key': process.env.STELACE_SECRET_API_KEY
    }
  })
  const res = await prodInstance.get('/workflows')
  console.log(res)
  return res.data
}

export async function editProd ({ commit }, object) {
  const axios = require('axios')
  const prodInstance = axios.create({
    baseURL: 'https://api.happycab.fr',
    headers: {
      'x-api-key': process.env.STELACE_SECRET_API_KEY
    }
  })
  const res = await prodInstance.patch('/workflows/' + object.id, object.data)
  commit('set', res.data)
  return res.data
}

export async function get ({ commit }, { id, logs = 10 }) {
  const res = await stelace.workflows.read(id, {
    logs
  })
  commit('set', res)
  return res
}

export async function getLogs ({ commit }, { id, logs }) {
  const res = await stelace.workflows.read(id, {
    logs
  })
  return res.logs
}

export async function add ({ commit }, object) {
  const res = await stelace.workflows.create(object)
  commit('set', res)
  return res
}

export async function remove ({ commit }, id) {
  const res = await stelace.workflows.remove(id)
  commit('set', null)
  return res
}

export async function edit ({ commit }, object) {
  const res = await stelace.workflows.update(object.id, object.data)
  commit('set', res)
  return res
}
