import { pick } from 'lodash'
import stelace from 'hc-core/composables/stelace'
import { remapToPaginated } from 'hc-core/composables/misc'

// Da is da niew wé
export async function list (context, params) {
  return remapToPaginated(
    await stelace.documents.list(
      pick(params, [
        'orderBy',
        'order',
        'page',
        'createdDate',
        'nbResultsPerPage',
        'id',
        'type',
        'label',
        'authorId',
        'targetId',
        'data',
        'metadata',
      ])
    )
  )
}

export async function create (context, attrs) {
  return await stelace.documents.create(attrs)
}

export async function read (context, { id }) {
  return await stelace.documents.read(id)
}

export async function update (context, { id, attrs }) {
  return await stelace.documents.update(id, attrs)
}

export async function remove (context, { id }) {
  return await stelace.documents.remove(id)
}

// OLD WAY
export async function fetch (context, { label = undefined, assetId = undefined, authorId = undefined, type = undefined, data = undefined, id = undefined }) {
  const res = await stelace.documents.list({
    nbResultsPerPage: 100,
    label,
    id,
    assetId,
    authorId,
    type,
    data
  })
  return res
}

export async function get ({ commit }, id) {
  const res = await stelace.documents.read(id)
  commit('set', res)
  return res
}

export async function add ({ commit }, object) {
  const res = await stelace.documents.create(object)
  commit('set', res)
  return res
}

// export async function remove ({ commit }, id) {
//   const res = await stelace.documents.remove(id)
//   commit('set', null)
//   return res
// }

export async function edit ({ commit }, object) {
  const res = await stelace.documents.update(object.id, object.data)
  commit('set', res)
  return res
}
