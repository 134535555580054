export default function () {
  return {
    list: [],
    current: null,
    conversation: null,
    conversations: null,
    messages: [],
    emails: [],
    inbox: []
  }
}
