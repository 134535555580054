/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/



import {QAvatar,QBadge,QBanner,QBreadcrumbs,QBreadcrumbsEl,QBtn,QBtnDropdown,QCard,QChip,QCardSection,QCarousel,QCarouselSlide,QChatMessage,QCheckbox,QDate,QDialog,QEditor,QExpansionItem,QForm,QHeader,QIcon,QInnerLoading,QItem,QItemLabel,QItemSection,QImg,QInput,QLayout,QList,QMarkupTable,QMenu,QNoSsr,QOptionGroup,QPage,QPageContainer,QPageSticky,QPagination,QPopupEdit,QPopupProxy,QRating,QScrollArea,QSelect,QSeparator,QSpace,QSplitter,QSpinnerPuff,QSpinnerRings,QTab,QTable,QTabPanel,QTabPanels,QTabs,QTd,QTimeline,QTimelineEntry,QTree,QToggle,QToolbar,QTooltip,QUploader,QUploaderAddTrigger,QVideo,Dialog,Notify,LocalStorage} from 'quasar'



export default { config: {"dark":"false"},components: {QAvatar,QBadge,QBanner,QBreadcrumbs,QBreadcrumbsEl,QBtn,QBtnDropdown,QCard,QChip,QCardSection,QCarousel,QCarouselSlide,QChatMessage,QCheckbox,QDate,QDialog,QEditor,QExpansionItem,QForm,QHeader,QIcon,QInnerLoading,QItem,QItemLabel,QItemSection,QImg,QInput,QLayout,QList,QMarkupTable,QMenu,QNoSsr,QOptionGroup,QPage,QPageContainer,QPageSticky,QPagination,QPopupEdit,QPopupProxy,QRating,QScrollArea,QSelect,QSeparator,QSpace,QSplitter,QSpinnerPuff,QSpinnerRings,QTab,QTable,QTabPanel,QTabPanels,QTabs,QTd,QTimeline,QTimelineEntry,QTree,QToggle,QToolbar,QTooltip,QUploader,QUploaderAddTrigger,QVideo},plugins: {Dialog,Notify,LocalStorage} }

