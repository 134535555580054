import stelace from 'hc-core/composables/stelace'
import _, { map, set, uniq } from 'lodash'

// New way
export async function listV2 ({ commit, dispatch }, { id = undefined, page = 1, nbResultsPerPage = 10, orderBy = 'createdDate', order = 'desc', withEntities = false, payerId = undefined, createdDateGte = undefined, createdDateLte = undefined, onlyResults = false }) {
  if (id === '') id = undefined
  let res = await stelace.orders.list({
    nbResultsPerPage,
    page,
    order,
    orderBy,
    id,
    payerId,
  })
  const pMeta = res.paginationMeta

  // Default order list doesn't provide filtering between dates
  // TODO : create advanced endpoint so it aceept it
  if (createdDateGte !== undefined) res = res.filter(r => new Date(r.createdDate) >= new Date(createdDateGte))
  if (createdDateLte !== undefined) res = res.filter(r => new Date(r.createdDate) <= new Date(createdDateLte))

  // Fetch related users
  if (res.length && withEntities) {
    const ids = uniq(map(res, 'payerId'))
    const users = await dispatch('user/list', { id: ids }, { root: true })
    res = res.map((r) => { return set(r, 'payer', users.results.find(u => u.id === r.payerId)) })
  }
  commit('setOrders', { key: 'list', arr: res })
  return onlyResults ? res : {
    results: res,
    page: _.get(pMeta, 'page', 0),
    nbPages: _.get(pMeta, 'nbPages', 0),
    nbResults: _.get(pMeta, 'nbResults', 0),
    nbResultsPerPage: _.get(pMeta, 'nbResultsPerPage', 0),
  }
}

// Old way
export async function list ({ commit }, { pagination, payerId }) {
  if (pagination) {
    const order = !pagination.descending ? 'asc' : 'desc'
    const res = await stelace.orders.list({
      nbResultsPerPage: pagination.rowsPerPage,
      payerId,
      page: pagination.page,
      order,
      orderBy: pagination.sortBy
    })
    commit('list', res)
    commit('setPagination', res.paginationMeta)
    return res
  } else {
    const res = await stelace.orders.list({
      nbResultsPerPage: 100,
      payerId
    })
    commit('list', res)
    return res
  }
}

export async function get ({ commit }, id) {
  const res = await stelace.orders.read(id)
  commit('set', res)
  return res
}

export async function add ({ commit }, object) {
  const res = await stelace.orders.create(object)
  commit('set', res)
  return res
}

export async function move ({ commit }, object) {
  const res = await stelace.orderMoves.create(object)
  commit('set', res)
  return res
}

export async function remove ({ commit }, id) {
  const res = await this._vm.$stelaxios.delete('/orders/advanced/' + id)
  commit('set', null)
  return res
}

export async function edit ({ commit }, object) {
  const res = await stelace.orders.update(object.id, object.data)
  commit('set', res)
  return res
}

export async function editLine ({ commit }, object) {
  console.log(stelace)
  const res = await stelace.orderLines.update(object.id, object.data)
  commit('set', res)
  return res
}
