import _ from 'lodash'
import sendinblue from 'composables/sendinblue.js'
import stelace, { fetchAllResults } from 'hc-core/composables/stelace'

export async function list ({ commit }, { query, pagination, senderId, receiverId, userId, topicId }) {
  if (query === '') query = undefined
  const request = { query, topicId }
  if (pagination !== undefined) {
    request.nbResultsPerPage = pagination.rowsPerPage
    request.page = pagination.page
    request.orderBy = pagination.sortBy
    request.order = !pagination.descending ? 'asc' : 'desc'
  }
  let res = null
  if (userId !== undefined) {
    request.senderId = userId
    const resSender = await stelace.messages.list(request)

    request.receiverId = userId
    delete request.senderId
    const resReceiver = await stelace.messages.list(request)

    res = resSender.concat(resReceiver)
  } else if (senderId !== undefined && receiverId !== undefined) {
    request.senderId = senderId
    request.receiverId = receiverId
    res = await stelace.messages.list(request)
  } else {
    res = await stelace.messages.list()
  }

  commit('list', res)
  // commit('setPagination', res.paginationMeta)
  return res
}

export async function get ({ commit }, id) {
  const res = await stelace.messages.read(id)
  commit('set', res)
  return res
}

export async function countMessages ({ commit }, id) {
  const messages = await stelace.messages.list()
  return {
    label: 'Messages',
    link: 'inbox',
    count: messages.paginationMeta.nbResults
  }
}

export async function getConversation ({ commit }, topicId) {
  const fetchMessages = (...args) => stelace.messages.list(...args)
  const res = await fetchAllResults(fetchMessages, {
    topicId
  })
  commit('setConversation', res)
  return res
}

export async function getConversations ({ commit }, { query, pagination, senderId, receiverId, userId, topicId }) {
  const fetchMessages = (...args) => stelace.messages.list(...args)
  if (query === '') query = undefined
  const request = { query, topicId }
  if (pagination !== undefined) {
    request.nbResultsPerPage = pagination.rowsPerPage
    request.page = pagination.page
    request.orderBy = pagination.sortBy
    request.order = !pagination.descending ? 'asc' : 'desc'
  }
  let res = null
  if (userId !== undefined) {
    request.senderId = userId
    const resSender = await fetchAllResults(fetchMessages, request)

    request.receiverId = userId
    delete request.senderId
    const resReceiver = await fetchAllResults(fetchMessages, request)

    res = resSender.concat(resReceiver)
  } else {
    request.senderId = senderId
    request.receiverId = receiverId
    res = await fetchAllResults(fetchMessages, request)
  }

  commit('setConversations', res)
  // commit('setPagination', res.paginationMeta)
  return res
}

export async function getEmails ({ commit }, options = { limit: 100 }) {
  const res = await sendinblue.getEmailEventReport(options)
  commit('setEmails', res)
  return res
}

export async function add ({ commit }, object) {
  const res = await stelace.messages.create(object)
  commit('set', res)
  return res
}

export async function remove ({ commit }, id) {
  const res = await stelace.messages.remove(id)
  commit('set', null)
  return res
}

export async function edit ({ commit }, object) {
  const res = await stelace.messages.update(object.id, object.data)
  commit('set', res)
  return res
}

// New way of fetching inbox inspired by app repo
export async function fetchInbox ({ commit }, { userId }) {
  // Gather all messages sent or received by userId
  const fetchMessages = (...args) => stelace.messages.list(...args)
  let messages = await fetchAllResults(fetchMessages, { userId })

  // Sort messages by creation && conversations by newest first
  messages = _.orderBy(messages, 'createdDate', 'desc')

  const timeSortedConversationsIds = _.uniq(messages.map((m) => m.conversationId))

  // Map users by id
  // TODO: convert to fetchAll to ensure everyone is fetched
  const users = await stelace.users.list({ nbResultsPerPage: 100, id: _.uniq(_.concat(messages.map((m) => m.senderId), messages.map((m) => m.receiverId))) })

  // Map conversations by id
  const conversationsArray = []
  for (const convId of timeSortedConversationsIds) {
    const conv = {}
    _.set(conv, 'messages', messages.filter((m) => m.conversationId === convId && !_.get(m, 'metadata.scheduledToSend', false)))
    _.set(conv, 'scheduledToSend', messages.filter((m) => m.conversationId === convId && _.get(m, 'metadata.scheduledToSend', false)))
    _.set(conv, 'convId', convId)
    const firstMessage = _.get(conv, 'messages[0]')
    if (firstMessage) {
      _.set(conv, 'topicId', firstMessage.topicId, null)
      _.set(conv, 'interlocutor', users.find(u => u.id === (firstMessage.senderId !== userId ? firstMessage.senderId : firstMessage.receiverId)))
    }
    // Unread numbers
    _.set(conv, 'nbUnread', _.get(conv, 'messages', []).filter((m) => m.receiverId === userId && !m.read).length)
    conversationsArray.push(conv)
  }

  // Remove duplicates conversations, maybe not the best way (still, was improved)
  const alreadyMerged = []
  for (const conv of conversationsArray) {
    if (alreadyMerged.includes(conv.convId)) continue
    const sameInterlocutorConvs = conversationsArray.filter(c => c.interlocutor.id === conv.interlocutor.id && c.convId !== conv.convId)
    if (sameInterlocutorConvs.length) {
      for (const sameConv of sameInterlocutorConvs) {
        _.set(conv, 'messages', _.orderBy(_.uniq(_.concat(conv.messages, sameConv.messages), 'createdDate', 'desc')))
        _.set(conv, 'scheduledToSend', _.orderBy(_.concat(conv.scheduledToSend, sameConv.scheduledToSend), 'createdDate', 'desc'))
        _.set(conv, 'tmpUnread', conv.nbUnread + sameConv.nbUnread)
        alreadyMerged.push(sameConv.convId)
      }
    }
  }

  commit('setInbox', conversationsArray)
  return conversationsArray
}

export async function markConversationAsRead ({ dispatch, rootGetters }, { ids }) {
  if (ids.length) {
    for (const id of ids) await stelace.messages.update(id, { read: true })
  }
}
