import stelace from 'hc-core/composables/stelace'

export async function login ({ commit }, credentials) {
  const res = await stelace.auth.login(credentials)
  commit('setToken', res)
  const resUser = await stelace.users.read(res.userId)
  if (resUser.roles.includes('admin') || resUser.roles.includes('dev') || resUser.roles.includes('manager')) {
    // await stelace.events.create({
    //   type: 'manager_login',
    //   objectId: resUser.id,
    //   emitterId: 'happycab-v2'
    // })
    commit('setUser', resUser)
    return res
  } else {
    commit('setToken', null)
    return null
  }
}

export async function fetchUser ({ commit }, id) {
  const res = await stelace.users.read(id)
  // commit('setUser', res)
  return res
}

export async function loginAs ({ commit }, userId) {
  try {
    const res = await stelace.forward.post('auth/advanced/loginAs', {
      targetId: userId
    })
    return res
  } catch (error) {
    return error
  }
}

export async function requestCheckToken ({ commit }, token) {
  const res = await this._vm.$stelaxios.post('/token/check/request', token)
  console.log(res)
  // // commit('setUser', res)
  return res
}

export async function apisConsumption (context) {
  return await stelace.forward.get('auth/advanced/apisConsumption')
}

export async function createToken (context, { userId = undefined, type = undefined, expirationDate, data }) {
  return await stelace.tokens.checkRequest({ data, type, userId, expirationDate })
}
