<template>
  <QPopupProxy
    v-if="focused === name"
    ref="FocusProxy"
    v-model="isThisFocused"
    class="hc-focused"
  >
    <QBanner>
      <template #avatar>
        <QIcon
          :name="icon"
          color="primary"
        />
      </template>
      <AppContent :path="path + name" />
    </QBanner>
  </QPopupProxy>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: null
    },
    path: {
      type: String,
      default: 'prompt.'
    },
    icon: {
      type: String,
      default: 'uil:info-circle'
    }
  },
  computed: {
    focused () { return this.$store.state.common.focused },
    isThisFocused () { return this.focused === this.name }, // Used by v-model to show alert
  }
}
</script>
