import { route } from 'quasar/wrappers'
import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'
import routes from './routes'

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function ({ store }) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

  const Router = createRouter({
    scrollBehavior: () => ({ x: 0, y: 0 }),
    routes,

    // Leave these as they are and change in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    mode: process.env.VUE_ROUTER_MODE,
    base: process.env.VUE_ROUTER_BASE,
    history: createHistory(process.env.MODE === 'ssr' ? undefined : process.env.VUE_ROUTER_BASE)
  })

  if (process.env.CLIENT) {
    Router.beforeEach((to, from, next) => {
      store.commit('auth/set')
      // See if any of the matched routes has meta "requiresAuth"
      if (to.matched.some(route => route.meta.mustBeLogged)) {
        // Yes this route requires authentication. See if the user is authenticated.
        if (store.getters['auth/isLoggedIn']) {
          // User is authenticated, we allow access.
          next()
        } else {
          // User is not authenticated. We can redirect her to
          // our login page. Or wherever we want.
          next('/')
        }
      } else {
        next()
      }
    })
  }

  return Router
})
