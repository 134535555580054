export function list (state, list) {
  state.list = list
}

export function set (state, object) {
  state.current = object
}

export function setPagination (state, pagination) {
  state.pagination = pagination
}
