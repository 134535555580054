import Vuex from 'vuex'
import { store } from 'quasar/wrappers'

import asset from './asset'
import content from './content'
import common from './common'
import order from './order'
import message from './message'
import transaction from './transaction'
import workflow from './workflow'
import task from './task'
import event from './event'
import rating from './rating'
import template from './template'
import document from './document'
import user from './user'
import auth from './auth'
import nav from './nav'
import stripe from './stripe'
import email from './email'
import google from './google'

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default store(function (/* { ssrContext } */) {
  const Store = new Vuex.Store({
    modules: {
      asset,
      template,
      content,
      common,
      order,
      email,
      message,
      transaction,
      document,
      task,
      workflow,
      event,
      rating,
      user,
      auth,
      nav,
      stripe,
      google
    },
    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: process.env.DEV
  })

  return Store
})
