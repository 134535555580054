import sendinblue from 'composables/sendinblue.js'

export async function getEmails ({ commit }, options = { limit: 100 }) {
  const res = await sendinblue.getEmailEventReport(options)
  commit('setEmails', res)
  return res
}

export async function send ({ commit, state, rootState }, email) {
  console.log(rootState)
  const token = rootState.auth.token
  console.log(token)
  const res = await this._vm.$axios.post('https://api.happycab.fr/emails/send-template', email, {
    headers: {
      authorization: 'Stelace-V1 apiKey=' + process.env.STELACE_PUBLISHABLE_API_KEY + ', token=' + token
    }
  })
  return res
}
