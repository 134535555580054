// Config, Asset Types and Custom Attributes included in app for performance.
// Do not edit this file automatically generated during build,
// but update configuration via Stelace API if needed.
// import config from './config-copy.json'
// import assetTypes from './asset-types-copy.json'
// import customAttributes from './custom-attributes-copy.json'

// import { isEmpty } from 'lodash'
// import { populateCustomAttributes } from 'src/utils/custom-attributes'

// Categories are not included in bundle as there can be many of these

// const now = new Date().toISOString()
// const assetTypesById = keyBy(assetTypes, 'id')
// const ca = populateCustomAttributes(customAttributes)
// const customAttributesById = keyBy(ca, 'id')

export default {
  assetTypesById: {},
  categoriesById: {},
  rolesById: {},
  customAttributesById: {},
  config: {},
  title: null,
  focused: null,
  meta: {
    title: 'HappyCab x VDC',
    description: 'La plateforme où les cabinets, les entreprises et les candidats les plus cools se rencontrent',
    url: 'https://happycab.fr',
    type: 'website',
    fbAppId: '2165029433804883',
    image: 'platform/branding/default-img-hc.jpg'
  },
  script: {
    ldJson: {
      type: 'application/ld+json',
      innerHTML: JSON.stringify({
        '@context': 'http://schema.org',
        '@type': 'WebPage'
      })
    }
  },
  // Null date forces to fetch latest resource version once needed in app,
  // in particular when bundled version (config, assetType, customAttributes) is empty.
  // This impacts app performance due to blocking API requests (especially config)
  // assetTypesLastFetchedDate: isEmpty(assetTypes) ? null : now,
  // categoriesLastFetchedDate: null,
  // rolesLastFetchedDate: null,
  // customAttributesLastFetchedDate: isEmpty(customAttributes) ? null : now,
  // configLastFetchedDate: isEmpty(config) ? null : now,

  // fetchingCategories: false,
  // fetchingCategoriesPromise: null,
  uploadingResume: false,
  offerCategoriesIds: [],
}
