import _, { groupBy } from 'lodash'

// WIP : flex way
export function setAssets (state, { key, arr = [] }) {
  _.set(state, key, arr)
}

export function list (state, list) {
  state.list = list
}

export function listOffers (state, offers) {
  state.offers = offers
}

export function listResumes (state, resumes) {
  state.resumes = resumes
}

export function listVdcAnecdotes (state, vdcAnecdotes) {
  state.vdcAnecdotes = vdcAnecdotes || []
}

export function listCompanies (state, companies) {
  state.companies = companies
}

export function updateCompany (state, company) {
  const companies = []
  for (const stateCompany of state.companies) {
    if (stateCompany.id === company.id) companies.push(company)
    else companies.push(stateCompany)
  }
  state.companies = companies
}

export function updateOffer (state, offer) {
  const offers = []
  for (const stateOffer of state.offers) {
    if (stateOffer.id === offer.id) offers.push(offer)
    else offers.push(stateOffer)
  }
  state.offers = offers
}

export function set (state, object) {
  state.current = object
}

export function stats (state, stats) {
  state.stats = stats
}

export function setPagination (state, pagination) {
  state.pagination = pagination
}

export function setESMapping (state, mapping) {
  state.mapping = mapping
}

export function listTypes (state, types) {
  state.types = types
}

export function listCategories (state, categories) {
  state.categories = categories
}

export function setCompanies (state, companies) {
  const companiesById = {}
  const companiesByOwnerId = {}
  for (const company of companies) {
    companiesById[company.id] = company
    companiesByOwnerId[company.ownerId] = company
  }
  state.companiesById = companiesById
  state.companiesByOwnerId = companiesByOwnerId
}

export function setOffers (state, offers) {
  state.offers = offers
  state.offersById = groupBy(offers, 'id')
  state.offersByOwnerId = groupBy(offers, 'ownerId')
}

export function setAllResumes (state, resumes) {
  const resumesById = {}
  const resumesByOwnerId = {}
  for (const resume of resumes) {
    resumesById[resume.id] = resume
    resumesByOwnerId[resume.ownerId] = resume
  }
  state.resumesById = resumesById
  state.resumesByOwnerId = resumesByOwnerId
}

export function listAttributes (state, attributes) {
  state.attributes = attributes
}
export function getAttribute (state, attribute) {
  state.attribute = attribute
}
// export function setAssets (state, assets) {
//   state.assets = assets
// }
