<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  meta: {
    title: `HC Manager ${process.env.STELACE_API_URL.replace('api.happycab.fr', '')}`,
  },
  created () {
    this.$q.iconMapFn = (iconName) => {
      if (iconName.startsWith('uil:') === true) return { cls: `uil uil-${iconName.substring(4)}` }
    }
  },
}
</script>
