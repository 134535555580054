export function i18nContent (state) {
  return state.i18nContent
}

export function getAvatarImageUrl (state, getters) {
  console.log('content/getter/getAvatarImageUrl : need fix ?')
  return (user, { resolution = 2 } = {}) => {
    const imgUri = user.avatarUrl || ''
    const avatarSquareSize = Math.round(resolution) * getters.avatarImageWidth

    return cdn.servedFromCdnBucket(imgUri) /* eslint-disable-line no-undef */
      ? cdn.getUrl(imgUri, { /* eslint-disable-line no-undef */
        webp: state.acceptWebP,
        resize: { width: avatarSquareSize, height: avatarSquareSize }
      })
      : imgUri
  }
}
