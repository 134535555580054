import { clone, map, uniq, set } from 'lodash'
import { compactObjectDeep } from 'hc-core/composables/misc'
import stelace, { fetchAllResults, fetchAllResultsAdvanced, setOwnerIdFilter } from 'hc-core/composables/stelace'

// Da is da niew wé
export async function create (context, { attrs }) {
  return await stelace.assets.create(attrs)
}

export async function read (context, { id }) {
  return await stelace.assets.read(id)
}

export async function update (context, { id, attrs }) {
  return await stelace.assets.update(id, attrs)
}

export async function listV2 ({ commit, dispatch }, { query, id, page = 1, nbResultsPerPage = 10, orderBy = 'createdDate', order = 'desc', assetTypeId = undefined, withEvents = false, withEntities = false, filters = {}, ownerId = undefined, metadata = undefined, platformData = undefined, customAttributes = undefined, active = undefined, validated = undefined }) {
  if (query === '') query = undefined
  if (id === '') id = undefined
  const res = await stelace.forward.get('/assets/advanced', {
    nbResultsPerPage,
    page,
    order,
    orderBy,
    id,
    query,
    assetTypeId,
    ownerId,
    active,
    validated,
    metadata: compactObjectDeep(metadata),
    platformData: compactObjectDeep(platformData),
    customAttributes: compactObjectDeep(customAttributes),
    ...filters,
  })

  // Fetch reladted orgs / companies
  const ids = uniq(map(res.results, 'ownerId'))
  if (ids.length > 0) {
    if (withEvents) {
      const eventRes = await dispatch('event/listAll', { objectId: uniq(map(res.results, 'id')) }, { root: true })
      res.results = res.results.map((r) => { return set(r, 'events', eventRes.filter(e => e.objectId === r.id)) })
    }
    if (withEntities) {
      // Allowing only fetching some fields to ligthen requests, owtherwise all fields by default
      const entities = await dispatch('user/fetchUsersEntities', {
        ids,
        fields: ['companies', 'implantations', 'offers', 'applications', 'ratings']
      }, { root: true })
      for (const asset of res.results) {
        if (entities && entities[asset.ownerId]) asset.entities = clone(entities[asset.ownerId])
      }
    }
  }
  commit('setAssets', { key: 'list', arr: res })
  return res
}

// Old way
export async function list ({ commit }, { query = undefined, pagination = {}, ownerId = undefined, assetTypeId = undefined, createdDateGte = undefined }) {
  const order = !pagination.descending ? 'asc' : 'desc'
  if (query === '') query = undefined
  const res = await stelace.assets.list({
    nbResultsPerPage: pagination.rowsPerPage,
    page: pagination.page,
    'createdDate[gte]': createdDateGte,
    assetTypeId,
    order,
    ownerId,
    orderBy: pagination.sortBy,
    query
  })
  commit('list', res)
  commit('setPagination', res.paginationMeta)
  return res
}

export async function findWithDates ({ commit }, { query, pagination, ownerId, assetTypeId, createdDateGte, createdDateLte }) {
  if (query === '') query = undefined
  const fetchAssets = (...args) => stelace.forward.get('assets/advanced', ...args)
  let assets = await fetchAllResultsAdvanced(fetchAssets, {
    'createdDate[gte]': createdDateGte,
    'createdDate[lte]': createdDateLte,
    assetTypeId,
    ownerId,
    query
  })
  const fetchUsers = (...args) => stelace.forward.get('users/advanced', ...args)
  const owners = await fetchAllResultsAdvanced(fetchUsers, {
    // role: 'client', // Removed since organizations don't have this role
    id: uniq(map(assets, 'ownerId')).join(',')
  })
  assets = assets.map((a) => { return set(a, 'owner', owners.find(o => o.id === a.ownerId) ?? null) })
  return assets
}

export async function listAllOffers ({ commit, rootState }, { query, categoryId, filter = undefined, pagination, ownerId, assetTypeId, active = null, validated }) {
  const fetchOffers = (...args) => stelace.search.list(...args)
  // var res = await stelace.search.list(req)
  const results = await fetchAllResults(fetchOffers, {
    // nbResultsPerPage: pagination.rowsPerPage,
    // page: pagination.page,
    assetTypeId,
    filter: filter ?? await setOwnerIdFilter(ownerId),
    categoryId,
    active,
    validated,
    query,
    logEvent: false
  })
  return results
}

export async function listOffers ({ commit, rootState }, { query, categoryId, filter = undefined, pagination, ownerId, assetTypeId, active = null, validated }) {
  const req = {
    nbResultsPerPage: pagination.rowsPerPage,
    page: pagination.page,
    assetTypeId,
    filter,
    categoryId,
    validated,
    active,
    query,
    logEvent: false
  }
  const filter2 = await setOwnerIdFilter(ownerId)
  if (filter2) req.filter = filter2
  const res = await stelace.search.list(req)
  commit('listOffers', res)
  return res
}

export async function listResumes ({ commit, rootState }, { query, categoryId, pagination, ownerId, assetTypeId, active = null }) {
  const order = !pagination.descending ? 'asc' : 'desc'
  if (query === '') query = undefined
  let res = null
  if (query === '' || query === undefined) {
    res = await stelace.assets.list({
      nbResultsPerPage: pagination.rowsPerPage,
      page: pagination.page,
      assetTypeId,
      categoryId,
      order,
      ownerId,
      orderBy: pagination.sortBy,
      query
    })
  } else {
    // var categories = categoryId.split(',')
    res = await stelace.search.list({
      nbResultsPerPage: pagination.rowsPerPage,
      page: pagination.page,
      assetTypeId,
      categoryId,
      ownerId,
      active,
      query,
      logEvent: false
    })
  }
  commit('listResumes', res)
  return res
}

export async function listVdcStories ({ commit, rootState }, { categoryId, pagination, ownerId, assetTypeId, active = null }) {
  const order = !pagination.descending ? 'asc' : 'desc'
  const res = await stelace.assets.list({
    nbResultsPerPage: pagination.rowsPerPage,
    page: pagination.page,
    assetTypeId,
    order,
    ownerId,
    orderBy: pagination.sortBy,
  })
  commit('listVdcAnecdotes', res)
  return res
}

export async function countAssets ({ commit, rootGetters }) {
  const companies = await stelace.assets.list({
    assetTypeId: rootGetters['nav/companyTypeId']
  })
  const offers = await stelace.assets.list({
    assetTypeId: rootGetters['nav/offerTypeId']
  })
  const resumes = await stelace.assets.list({
    assetTypeId: rootGetters['nav/resumeTypeId']
  })
  return {
    companies: {
      label: 'Entreprises',
      link: 'companies',
      count: companies.paginationMeta.nbResults
    },
    offers: {
      label: 'Offres d\'emploi',
      link: 'offers',
      count: offers.paginationMeta.nbResults
    },
    resumes: {
      label: 'CV',
      link: 'resumes',
      count: resumes.paginationMeta.nbResults
    }
  }
}

export async function listCompanies ({ commit, rootState }, { query, filter = undefined, pagination, ownerId, assetTypeId, active = null, validated }) {
  const order = !pagination.descending ? 'asc' : 'desc'
  // const categoryId = join(rootState.nav.config.stelace.instant.searchOptions.modes.company.categoryIds, ',')
  let res = null
  if ((query === '' || query === undefined) && filter === undefined) {
    res = await stelace.assets.list({
      nbResultsPerPage: pagination.rowsPerPage,
      page: pagination.page,
      assetTypeId,
      // categoryId: categoryId,
      order,
      ownerId,
      active,
      validated,
      orderBy: pagination.sortBy
    })
  } else {
    res = await stelace.search.list({
      nbResultsPerPage: pagination.rowsPerPage,
      page: pagination.page,
      filter,
      assetTypeId,
      // categoryId: categoryId,
      active,
      query,
      logEvent: false
    })
  }
  commit('listCompanies', res)
  return res
}

export async function listAllAssets ({ commit, rootState }, { query, pagination, ownerId, assetTypeId, active = null }) {
  let finalRes = []
  let page = 1
  const res = await stelace.assets.list({
    nbResultsPerPage: 100,
    page: 1,
    order: 'asc'
  })
  page++
  finalRes = finalRes.concat(res)
  while (page <= res.paginationMeta.nbPages) {
    const resloop = await stelace.assets.list({
      nbResultsPerPage: 100,
      page,
      order: 'asc'
    })
    finalRes = finalRes.concat(resloop)
    page++
  }
  return finalRes
}

export async function search ({ commit }, { query, pagination, ownerId, assetTypeId }) {
  if (query === '') query = undefined
  const req = {
    nbResultsPerPage: pagination.rowsPerPage,
    page: pagination.page,
    assetTypeId,
    orderBy: pagination.sortBy,
    query,
    logEvent: false
  }
  if (ownerId !== null) req.filter = '_ownerId = ' + ownerId
  const res = await stelace.search.list(req)
  commit('list', res)
  commit('setPagination', res.paginationMeta)
  return res
}

export async function setCompanies ({ commit, rootGetters }) {
  const fetchCompanies = (...args) => stelace.assets.list(...args)
  const companiesResults = await fetchAllResults(fetchCompanies, {
    assetTypeId: rootGetters['nav/companyTypeId']
  })
  commit('setCompanies', companiesResults)
  return companiesResults
}

export async function fetchAllCompanies ({ commit, rootGetters }, ids) {
  const fetchCompanies = (...args) => stelace.assets.list(...args)
  const companiesResults = await fetchAllResults(fetchCompanies, {
    assetTypeId: rootGetters['nav/companyTypeId'],
    ownerId: ids
  })
  commit('setCompanies', companiesResults)
  return companiesResults
}

export async function getAllCompanies ({ commit, rootGetters }, ids) {
  const fetchCompanies = (...args) => stelace.assets.list(...args)
  const companiesResults = await fetchAllResults(fetchCompanies, {
    assetTypeId: rootGetters['nav/companyTypeId'],
    ownerId: ids
  })
  return companiesResults
}

export async function searchAllCompanies ({ commit, rootGetters }, query) {
  const fetchAssets = (...args) => stelace.search.list(...args)
  const companiesResults = await fetchAllResults(fetchAssets, {
    assetTypeId: rootGetters['nav/companyTypeId'],
    active: null,
    query,
    logEvent: false
  })
  commit('setCompanies', companiesResults)
  return companiesResults
}

export async function searchCompanies ({ commit, rootGetters }, query) {
  const fetchAssets = (...args) => stelace.search.list(...args)
  const companiesResults = await fetchAllResults(fetchAssets, {
    assetTypeId: rootGetters['nav/companyTypeId'],
    query,
    logEvent: false
  })
  return companiesResults
}

export async function searchAllOffers ({ commit, rootGetters }, { query, ownerIds }) {
  const fetchAssets = (...args) => stelace.search.list(...args)
  const req = {
    assetTypeId: rootGetters['nav/offerTypeId'],
    query,
    logEvent: false
  }
  const filter = await setOwnerIdFilter(ownerIds)
  if (filter) req.filter = filter
  const offersResults = await fetchAllResults(fetchAssets, req)
  return offersResults
}

export async function fetchAllAssetsWithAttribute ({ commit, rootGetters, state }, { ids = [], attribute = undefined }) {
  // if (state.assets.length > 0) {
  //   var res = await stelace.assets.list()
  //   console.log(res)
  // }
  const fetchAssets = (...args) => stelace.assets.list(...args)
  const assetsResults = await fetchAllResults(fetchAssets, { ownerId: ids })
  const assets = []
  for (const asset of assetsResults) {
    if (asset.customAttributes && asset.customAttributes[attribute]) {
      assets.push(asset)
    }
  }
  commit('setAssets', assets)
  return assets
}

export async function fetchOffers ({ commit, rootGetters }, { ids, ownerIds, active, defaultOffer = false, categoryId }) {
  const response = await stelace.forward.post('offers', {
    id: ids, ownerId: ownerIds, active, defaultOffer, categoryId, orderBy: 'createdDate', order: 'desc', page: 1, nbResultsPerPage: 1000
  })
  const offers = response.results
  return offers
}

export async function fetchAllOffers ({ commit, rootGetters }, { ids, ownerIds, defaultOffer = false, active, categoryId }) {
  const response = await stelace.forward.post('offers', {
    id: ids, ownerId: ownerIds, active, defaultOffer, categoryId, orderBy: 'createdDate', order: 'desc', page: 1, nbResultsPerPage: 1000
  })
  const offers = response.results
  commit('setOffers', offers)
  return offers
}

export async function fetchAllResumes ({ commit, rootGetters }, ids) {
  const fetchResumes = (...args) => stelace.assets.list(...args)
  const res = await fetchAllResults(fetchResumes, {
    assetTypeId: rootGetters['nav/resumeTypeId'],
    ownerId: ids
  })
  commit('setAllResumes', res)
  return res
}

export async function stats ({ commit, state }) {
  const stats = []
  for (const type of state.types) {
    const res = await stelace.assets.list({ assetTypeId: type.id })
    stats.push({
      type: type.name,
      count: res.paginationMeta.nbResults
    })
  }
  commit('stats', stats)
  return stats
}

export async function get ({ commit }, id) {
  const res = await stelace.assets.read(id)
  commit('set', res)
  return res
}

export async function getWithOwner ({ commit }, id) {
  const res = await stelace.assets.read(id)
  const resOwner = await stelace.users.read(res.ownerId)
  const user = res
  user.owner = resOwner
  commit('set', res)
  return res
}

export async function find ({ commit }, { query, platformData, metadata }) {
  const res = await this._vm.$stelaxios.get('/assets/advanced', {
    params: {
      platformData,
      metadata,
      query
    }
  })
  return res.data
}

export async function add ({ commit }, object) {
  const res = await stelace.assets.create(object)
  commit('set', res)
  return res
}

export async function remove ({ commit, dispatch }, id) {
  const transactions = await dispatch('transaction/list', { assetId: id }, { root: true })
  for (const transaction of transactions) {
    await dispatch('transaction/remove', transaction.id, { root: true })

    const messages = await dispatch('message/list', { topicId: transaction.id }, { root: true })
    for (const message of messages) {
      await stelace.messages.remove(message.id)
    }
  }
  const res = await stelace.assets.remove(id)
  commit('set', null)
  return res
}

export async function edit ({ commit }, object) {
  const res = await stelace.assets.update(object.id, object.data)
  commit('set', res)
  return res
}

export async function deleteAsset ({ commit }, id) {
  const res = await stelace.assets.remove(id)
  return res
}

export async function listTypes ({ commit }, query) {
  const res = await stelace.assetTypes.list()
  commit('listTypes', res)
  return res
}

export async function addType ({ commit }, object) {
  const res = await stelace.assetTypes.create(object)
  return res
}

export async function removeType ({ commit }, id) {
  const res = await stelace.assetTypes.remove(id)
  return res
}

export async function listCategories ({ commit }, query) {
  const res = await stelace.categories.list()
  commit('listCategories', res)
  return res
}

export async function addCategory ({ commit }, object) {
  const res = await stelace.categories.create(object)
  return res
}

export async function removeCategory ({ commit }, id) {
  const res = await stelace.categories.remove(id)
  return res
}

export async function listAttributes ({ commit }, query) {
  const res = await stelace.customAttributes.list()
  commit('listAttributes', res)
  return res
}

export async function getAttribute ({ commit }, id) {
  const res = await stelace.customAttributes.read(id)
  commit('getAttribute', res)
  return res
}

export async function editAttribute ({ commit }, { id, attrs }) {
  const res = await stelace.customAttributes.update(id, attrs)
  return res
}

export async function addAttribute ({ commit }, object) {
  const res = await stelace.attributes.create(object)
  return res
}

export async function removeAttribute ({ commit }, id) {
  const res = await stelace.attributes.remove(id)
  return res
}
