import stelace from 'hc-core/composables/stelace'
//  ########################## CHECKED, OK TO USE
export async function getStripeInformations ({ commit, dispatch, state }, customerId) {
  const customer = await stelace.forward.post('/integrations/stripe/request', {
    method: 'customers.retrieve',
    args: [customerId]
  })
  const subscriptions = await stelace.forward.post('/integrations/stripe/request', {
    method: 'subscriptions.list',
    args: [{ limit: 100, customer: customerId }]
  })
  const invoices = await stelace.forward.post('/integrations/stripe/request', {
    method: 'invoices.list',
    args: [{ limit: 100, customer: customerId }]
  })
  const methods = await stelace.forward.post('/integrations/stripe/request', {
    method: 'paymentMethods.list',
    args: [{ customer: customerId }]
  })

  return { customer, methods, subscriptions, invoices }
}

export async function sendStripeRequest (context, request) {
  if (!request) return null
  else return await stelace.forward.post('/integrations/stripe/request', request)
}

// some spacing

//  ########################## NOT CHECKED AFTER
export async function getProducts ({ commit, dispatch, state }, { type = null }) {
  const res = await stelace.forward.post('/integrations/stripe/request', {
    method: 'products.list',
    args: [
      { limit: 100 }
    ]
  })
  const products = []
  const resprices = await stelace.forward.post('/integrations/stripe/request', {
    method: 'prices.list',
    args: [
      { limit: 100, expand: ['data.tiers'] },
    ]
  })
  const prices = []
  for (const price of resprices.data) {
    if ((price.metadata.type || type === null)) {
      prices.push(price)
    }
  }
  for (const product of res.data) {
    product.prices = []
    for (const price of prices) {
      if (price.product === product.id && (price.metadata.type || type === null)) {
        product.prices.push(price)
      }
    }
    if ((type !== null && product.metadata.type === type) || type === null) products.push(product)
  }
  const resrates = await stelace.forward.post('/integrations/stripe/request', {
    method: 'taxRates.list',
    args: [
      { limit: 100 },
    ]
  })
  const rates = resrates.data
  console.log(rates)
  return products
}

export async function getRates () {
  const resrates = await stelace.forward.post('/integrations/stripe/request', {
    method: 'taxRates.list',
    args: [
      { limit: 100 },
    ]
  })
  return resrates.data
}

export async function createCustomer ({ commit, dispatch, state }, { customer }) {
  const res = await stelace.forward.post('/integrations/stripe/request', {
    method: 'customers.create',
    args: [
      customer
    ]
  })
  return res
}

export async function createInvoice ({ commit, dispatch, state }, { customer, subscription, discounts }) {
  const res = await stelace.forward.post('/integrations/stripe/request', {
    method: 'invoices.create',
    args: [
      customer,
      subscription,
      discounts
    ]
  })
  return res
}

export async function getPortalSession (store, userId) {
  try {
    const res = await stelace.forward.post('auth/advanced/loginAs', {
      targetId: userId
    })
    const axios = require('axios')
    const instance = axios.create({
      baseURL: process.env.STELACE_API_URL.includes('localhost') ? 'http://' + process.env.STELACE_API_URL : 'https://' + process.env.STELACE_API_URL,
      headers: {
        Authorization: 'Stelace-V1 apiKey=' + process.env.STELACE_PUBLISHABLE_API_KEY + ', token=' + res.accessToken
      }
    })
    const response = await instance.get('/integrations/stripe/portal/session')
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export async function getCustomer ({ commit, dispatch, state }, customerId) {
  const customer = await stelace.forward.post('/integrations/stripe/request', {
    method: 'customers.retrieve',
    args: [
      customerId
    ]
  })
  return customer
}

export async function updateCustomer ({ commit, dispatch, state }, { id, customer }) {
  console.log(id)
  console.log(customer)
  const res = await stelace.forward.post('/integrations/stripe/request', {
    method: 'customers.update',
    args: [
      id,
      customer
    ]
  })
  return res
}
