import axios from 'axios'
import { remapToPaginated } from 'hc-core/composables/misc'
import stelace, { fetchAllResults } from 'hc-core/composables/stelace'

// New way
export async function read (context, { id }) {
  return await stelace.events.read(id)
}

// Old way
export async function findOne (context, { type, objectId }) {
  const res = await stelace.events.list({
    nbResultsPerPage: 1,
    type,
    page: 1,
    order: 'desc',
    objectId,
    orderBy: 'createdDate'
  })
  return res[0]
}
export async function list ({ commit }, { nbResultsPerPage = 10, page = 1, orderBy = 'createdDate', order = 'desc', type = undefined, objectId = undefined }) {
  const res = await stelace.events.list({
    nbResultsPerPage,
    type,
    page,
    order,
    objectId,
    orderBy
  })
  commit('list', res)
  commit('setPagination', res.paginationMeta)
  return remapToPaginated(res)
}

export async function listAll ({ commit }, { type = undefined, nbResultsPerPage = 100, objectId, orderBy = 'createdDate', order = 'desc' }) {
  const fetchEvents = (...args) => stelace.events.list(...args)
  const res = await fetchAllResults(fetchEvents, {
    type,
    objectId,
    order,
    orderBy,
    nbResultsPerPage
  })
  commit('list', res)
  return res
}

export async function fetchAll (context, { type, objectId }) {
  const fetchEvents = (...args) => stelace.events.list(...args)
  const res = await fetchAllResults(fetchEvents, {
    type,
    objectId,
    nbResultsPerPage: 100
  })
  return res
}

export async function countAutomation (context) {
  const events = await stelace.events.list()
  const workflows = await stelace.workflows.list()
  const tasks = await stelace.tasks.list({ nbResultsPerPage: 100 })
  return {
    events: {
      label: 'Évènements',
      link: 'inbox',
      count: events.paginationMeta.nbResults
    },
    workflows: {
      label: 'Workflows',
      link: 'inbox',
      count: workflows.length
    },
    tasks: {
      label: 'Tasks',
      link: 'inbox',
      count: tasks.length
    }
  }
}

export async function count ({ commit }, { type, gte, lte }) {
  const res = await stelace.events.list({
    nbResultsPerPage: 1,
    type,
    createdDate: {
      gte,
      lte
    },
    page: 1,
    order: 'desc',
    orderBy: 'createdDate'
  })
  console.log(res.paginationMeta.nbResults)
  await commit('setEventCount', {
    type,
    count: res.paginationMeta.nbResults
  })

  return res.paginationMeta.nbResults
}

export async function listUserLeads ({ commit }, { userId }) {
  if (!userId) return []
  const res = await stelace.events.list({
    type: 'lead_meeting_requested',
    objectId: userId
  })
  return res
}

export async function getUserIP ({ commit }) {
  try {
    const instance = axios.create()
    const response = await instance.get('https://ipinfo.io/ip')
    return response.data
  } catch (error) {
    return '195.200.221.95'
    // return '35.180.46.184'
  }
}

export async function get ({ commit }, id) {
  const res = await stelace.events.read(id)
  commit('set', res)
  return res
}

export async function add ({ commit }, object) {
  const res = await stelace.events.create(object)
  commit('set', res)
  return res
}

export async function remove ({ commit }, id) {
  const res = await stelace.events.remove(id)
  commit('set', null)
  return res
}

export async function edit ({ commit }, object) {
  const res = await stelace.events.update(object.id, object.data)
  commit('set', res)
  return res
}
