<template>
  <QBtn
    :flat="flat"
    :href="href"
    :size="size"
    :color="color"
    :target="target"
    :disable="disable"
    :loading="loading"
    :unelevated="unelevated"
    :dense="isClose ? true : dense"
    :icon="isClose ? 'uil:times' : icon"
    :class="computedClass"
    :label="computedLabel ? computedLabel : ''"
    type="a"
    @click="$emit('click')"
  >
    <!-- Set type=a so tooltip shows even if btn is disabled -->
    <ActionTooltip
      v-if="computedTooltip"
      :str="computedTooltip"
      :plan-restricted="planRestricted"
    />
    <FocusProxy
      v-if="focus"
      :name="focus"
    />
    <slot />
  </QBtn>
</template>

<script>
import FocusProxy from 'hc-core/components/common/focus-proxy'

export default {
  components: { FocusProxy },
  props: {
    class: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'md'
    },
    color: {
      type: String,
      default: 'primary'
    },
    icon: {
      type: String,
      default: undefined
    },
    label: {
      type: String,
      default: null
    },
    tooltip: {
      type: String,
      default: null
    },
    flat: {
      type: Boolean,
      default: false
    },
    disable: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    unelevated: {
      type: Boolean,
      default: false
    },
    isClose: {
      type: Boolean,
      default: false
    },
    forceLabelOnXs: {
      type: Boolean,
      default: false
    },
    focus: {
      type: String,
      default: null
    },
    planRestricted: {
      type: String,
      default: null,
    },
    href: {
      type: String,
      default: undefined
    },
    target: {
      type: String,
      default: undefined
    },
  },
  emits: ['click'],
  computed: {
    computedClass () { return `q-py-sm q-px-md br-25 text-no-wrap ${this.$_.get(this, 'focus', false) === this.$store.state.common.focused && this.$store.state.common.focused ? 'hc-focused' : ''} ${this.class}` },
    computedLabel () {
      return this.label ? this.translationExists(this.label) ? this.$t({ id: this.label }) : this.label : false
    },
    computedTooltip () {
      if (this.loading) return this.$t({ id: 'status.loading' })
      else if (this.isClose) return this.$t({ id: 'prompt.close_button' })
      else if (this.planRestricted) return !this.isSubscribed() || (this.planRestricted === 'dragon' && this.isSubscribed() !== 'dragon') ? this.$t({ id: `notification.tooltip.${this.planRestricted}` }) : false
      else if (this.tooltip) {
        return this.translationExists(this.tooltip) ? this.$t({ id: this.tooltip }) : this.tooltip
      } else return false
    },
  }
}
</script>
