<template>
  <QPageSticky v-if="isSuperAdmin" position="bottom-left" :offset="[18, 18]">
    <QBtn round color="grey-5" icon="uil:arrow" @click="viewRawEditor = !viewRawEditor">
      <ActionTooltip str="Raw" />
    </QBtn>
    <QDialog v-model="viewRawEditor" :maximized="true">
      <QCard>
        <div class="row">
          <QSpace />
          <HCButton isClose @hide="viewRawEditor = false" />
        </div>
        <JsonEditor v-if="element !== null" :JSONData="element" mode readonly />
        <div v-else>
          No data.
        </div>
      </QCard>
    </QDialog>
  </QPageSticky>
</template>

<script>
import JsonEditor from 'components/JSONEditor'

export default {
  components: { JsonEditor },
  props: {
    element: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      viewRawEditor: false
    }
  }
}
</script>
