import _, { chunk, uniq, map, filter, keyBy, uniqWith, isEqual } from 'lodash'
import moment from 'moment'
import stelace, { fetchAllResults, fetchAllResultsAdvanced } from 'hc-core/composables/stelace'
import { compactObjectDeep } from 'hc-core/composables/misc'

export async function list ({ commit, dispatch }, {
  pagination = undefined,
  taker = false,
  owner = false,
  takerId = undefined,
  ownerId = undefined,
  userId = undefined,
  assetId,
  id = undefined,
  status = undefined,
  platformData = undefined,
  createdDate = undefined,
  updatedDate = undefined,
  withEntities = false,
  page = 1,
  nbResultsPerPage = 10,
  fetchAll = false
}) {
  let request = { id, userId, status, platformData, takerId, ownerId, assetId, nbResultsPerPage, page, createdDate, updatedDate }
  if (pagination !== undefined) {
    request.nbResultsPerPage = pagination.rowsPerPage
    request.page = pagination.page
    request.orderBy = pagination.sortBy
    request.order = !pagination.descending ? 'asc' : 'desc'
  }
  request = compactObjectDeep(request)

  // Only extract results, but all
  if (fetchAll) {
    return await fetchAllResults((...args) => stelace.forward.get('transactions/advanced', ...args), request)
  } else {
    const res = await stelace.forward.get('transactions/advanced', request)

    if (taker) {
      const users = await stelace.users.list({ id: uniq(map(res.results, 'takerId')), nbResultsPerPage: 100 })
      for (const transaction of res.results) {
        transaction.takerObject = _.find(users, { id: transaction.takerId })
      }
    }
    if (owner) {
      const users = await dispatch('user/list', { id: uniq(map(res.results, 'ownerId')), withEntities, fields: ['companies', 'implantations'] }, { root: true })
      for (const transaction of res.results) {
        transaction.ownerObject = _.find(users.results, { id: transaction.ownerId })
      }
    }
    commit('list', res.results)
    commit('setPagination', res.paginationMeta)
    commit('setTransactions', { key: 'list', arr: res })
    return res
  }
}

export async function getData ({ commit }, { pagination, taker, takerId, ownerId, userId, assetId, id, range }) {
  const request = { assetId }
  let res
  if (pagination !== undefined) {
    request.nbResultsPerPage = pagination.rowsPerPage
    request.page = pagination.page
    request.orderBy = pagination.sortBy
    request.order = !pagination.descending ? 'asc' : 'desc'
  }
  if (id) request.id = id
  if (range.startDate) {
    if (!request.createdDate) request.createdDate = {}
    request.createdDate.gte = moment(range.startDate).format('YYYY-MM-DD')
  }
  if (range.endDate) {
    if (!request.createdDate) request.createdDate = {}
    request.createdDate.lte = moment(range.endDate).format('YYYY-MM-DD')
  }
  if (userId !== undefined) {
    request.ownerId = userId
    const resOwner = await stelace.forward.get('transactions/advanced', request)

    request.takerId = userId
    delete request.ownerId
    const resTaker = await stelace.forward.get('transactions/advanced', request)

    res = resOwner.concat(resTaker)
  } else {
    request.ownerId = ownerId
    request.takerId = takerId
    res = await stelace.forward.get('transactions/advanced', request)
  }

  if (taker) {
    const ids = uniq(map(res, 'takerId'))
    const users = await stelace.users.list({ id: ids, nbResultsPerPage: 100 })
    for (const transaction of res) {
      transaction.takerObject = filter(users, { id: transaction.takerId })[0]
    }
  }
  return res
}

export async function findWithDates ({ commit, dispatch }, { query, pagination, ownerId, assetTypeId, createdDateGte, createdDateLte, filter }) {
  if (query === '') query = undefined
  const fetchTransactions = (...args) => stelace.forward.get('transactions/advanced', ...args)
  const res = await fetchAllResultsAdvanced(fetchTransactions, {
    'createdDate[gte]': createdDateGte,
    'createdDate[lte]': createdDateLte,
    assetTypeId,
    ownerId,
    query
  })
  if (filter === 'subscription') {
    const orgIds = uniq(map(res, 'ownerId'))
    const userIds = []
    for (const id of orgIds) {
      if (id.includes('usr_')) {
        userIds.push(id)
        orgIds.splice(orgIds.indexOf(id), 1)
      }
    }
    const users = await stelace.forward.get('users', {
      id: userIds
    })
    const orgsEntities = await stelace.forward.get('users/entities', {
      fields: ['implantations', 'users'],
      ids: orgIds
    })
    const usersEntities = keyBy(users.results, 'id')
    for (const result of res) {
      if (result.ownerId.includes('org_')) {
        result.subscription = _.get(orgsEntities, `[${result.ownerId}].implantations[0].subscription`, 'unknown')
        if (!result.subscription) result.subscription = 'etincelle'
      } else {
        const roles = _.get(usersEntities, `[${result.ownerId}].roles`, ['client'])
        result.subscription = roles.includes('dragon') ? 'dragon' : roles.includes('phenix') ? 'phenix' : roles.includes('client') ? 'etincelle' : 'unknown'
      }
      if (!result.subscription) {
        result.subscription = 'unknown'
        console.log(result)
      }
    }
  }
  return res
}

export async function count ({ commit }, { type }) {
  const res = await stelace.transactions.list({
    nbResultsPerPage: 1,
    page: 1,
    order: 'desc',
    orderBy: 'createdDate'
  })
  return res.paginationMeta.nbResults
}

export async function find ({ commit }, { query, platformData, metadata }) {
  const res = await this._vm.$stelaxios.get('/transactions/advanced', {
    params: {
      platformData,
      metadata,
      query
    }
  })
  return res.data
}

export async function fetchPendingApplications ({ commit }) {
  const resApplications = await this._vm.$stelaxios.get('/transactions/advanced', {
    params: {
      status: 'restricted',
      nbResultsPerPage: 100
    }
  })
  // Pack applications in offers
  const applications = resApplications.data.results
  const offerIds = map(applications, 'assetId')
  const resOffers = await stelace.assets.list({
    id: uniqWith(offerIds, isEqual),
    nbResultsPerPage: 100
  })

  const userIds = map(applications, 'takerId')
  const resTakers = await stelace.users.list({
    id: uniqWith(userIds, isEqual),
    nbResultsPerPage: 100
  })
  for (const application of applications) {
    for (const taker of resTakers) {
      if (taker.id === application.takerId) application.taker = taker
    }
  }

  // Count total applications for each offer
  for (const offer of resOffers) {
    offer.applications = []
    for (const application of resApplications.data.results) {
      if (application.assetId === offer.id) offer.applications.push(application)
    }
    // count total
    const countTotalRes = await stelace.transactions.list({
      assetId: offer.id,
      nbResultsPerPage: 100
    })
    offer.countTotal = countTotalRes.length
    offer.countRestricted = offer.applications.length
    console.log('COUNTING FOR EACH OFFER')
    console.log(countTotalRes)
  }

  // const ownerIds = map(resOffers, 'ownerId')
  // console.log('ownerIds')
  // console.log(ownerIds)
  return {
    applications,
    offers: resOffers
  }
}

export async function fetchAllApplicationsChunks ({ commit }, ids) {
  const fetchTransactions = (...args) => stelace.transactions.list(...args)

  let applications = []
  const usersIds = uniq(ids)
  const chunksIds = chunk(usersIds, 20)
  // console.log(chunksIds)
  console.log(chunksIds)
  for (const chunkIds of chunksIds) {
    const res = await fetchAllResults(fetchTransactions, {
      assetId: chunkIds
    })
    applications = applications.concat(res)
  }
  commit('setAllApplications', applications)
  return applications
}

export async function fetchAllApplications ({ commit }, ids) {
  if (ids && typeof ids === 'object') ids = ids.join(',')
  const fetchTransactions = (...args) => stelace.forward.get('transactions/advanced', ...args)
  const res = await fetchAllResultsAdvanced(fetchTransactions, {
    assetId: ids,
    nbResultsPerPage: 500
  })
  commit('setAllApplications', res)
  return res
}

export async function getApplications ({ commit }, ids) {
  if (ids && typeof ids === 'object') ids = ids.join(',')
  const fetchTransactions = (...args) => stelace.forward.get('transactions/advanced', ...args)
  const res = await fetchAllResultsAdvanced(fetchTransactions, {
    ownerId: ids,
    nbResultsPerPage: 500
  })
  return res
}

export async function get ({ commit }, id) {
  const res = await stelace.transactions.read(id)
  commit('set', res)

  const resOwner = await stelace.users.read(res.ownerId)
  commit('setOwner', resOwner)

  const resTaker = await stelace.users.read(res.takerId)
  commit('setTaker', resTaker)
  return res
}

export async function add ({ commit }, object) {
  const res = await stelace.transactions.create(object)
  commit('set', res)
  return res
}

export async function preview ({ commit }, object) {
  const res = await stelace.transactions.preview(object)
  commit('set', res)
  return res
}

export async function transition ({ commit }, object) {
  const res = await stelace.transactions.createTransition(object.transactionId, object.data)
  return res
}

export async function remove ({ commit }, id) {
  const res = await this._vm.$stelaxios.delete('/transactions/advanced/' + id)
  commit('set', null)
  return res
}

export async function edit ({ commit }, object) {
  const res = await stelace.transactions.update(object.id, object.data)
  commit('set', res)
  return res
}
