// import { remapToPaginated } from 'hc-core/composables/misc'
import _, { chunk, sortBy, uniq, map, get, flatten, pick } from 'lodash'
import stelace, { fetchAllResults, fetchAllResultsAdvanced } from 'hc-core/composables/stelace'

// New way

export async function create (context, { attrs }) {
  return await stelace.users.create(attrs)
}

export async function read (context, { id }) {
  return await stelace.users.read(id)
}

export async function update (context, { id, attrs }) {
  return await stelace.users.update(id, attrs)
}

export async function list ({ commit, dispatch }, params) {
  const res = await stelace.forward.get('users/advanced', Object.assign(
    {},
    { page: 1, nbResultsPerPage: 10, orderBy: 'createdDate', order: 'desc' }, // default list params
    pick(params, ['page', 'nbResultsPerPage', 'orderBy', 'order', 'role', 'userOrganizationId', 'type', 'id', 'query', 'metadata', 'platformData']) // request list params
  ))

  const { withEntities, filterRolesOnOrgs, fields } = Object.assign({},
    { withEntities: false, filterRolesOnOrgs: false, fields: undefined }, // other default params
    pick(params, ['withEntities', 'filterRolesOnOrgs']) // default request params
  )

  // const paginationMeta = _.pick(res, ['page', 'nbResultsPerPage', 'nbPages', 'nbResults']) // save so not erased
  if (filterRolesOnOrgs) res.results = res.results.filter((user) => uniq(flatten(Object.values(user.organizations).map(({ roles }) => roles))).includes(filterRolesOnOrgs))

  const ids = map(res.results, 'id')
  if (ids.length > 0 && (withEntities || (fields ?? []).includes('lastLogin'))) {
    // Allowing only fetching some fields to ligthen requests, owtherwise all fields by default
    const entities = await dispatch('getUsersElements', {
      id: ids,
      fields: fields ?? ['companies', 'implantations', 'users', 'offers', 'applications', 'ratings', 'templates', 'messages', 'lastLogin']
    })
    for (const user of res.results) {
      const userEnt = _.clone(res.results.length === 1 ? entities : entities[user.id])
      if (entities && userEnt) {
        user.entities = userEnt
        _.set(user, 'lastLogin', _.get(userEnt, 'lastLogin', undefined))
      }
      if (get(user, 'entities.implantations', []).length > 0) {
        for (const org of user.entities.implantations) org.company = _.get(_.filter(user.entities.companies, (c) => c.ownerId === org.id), '[0]')
        for (const offer of get(user, 'entities.offers', [])) offer.company = _.get(_.filter(user.entities.companies, (c) => c.ownerId === offer.ownerId), '[0]')
      }
    }
  }

  commit('setUsers', { key: 'list', arr: res })
  return res
}

export async function remove (context, { id }) {
  try {
    if (!id) return null
    // All type of resources are handled in this endpoint
    return await stelace.forward.del(`advanced/userspurge?ids=${id}`)
  } catch (e) { this.useLogger(e) }
}

export async function listAdvanced (context, params) {
  return await stelace.forward.get('users/advanced', params)
}

// Ask for a resume parsing and update of user
export async function affindaParseResume (context, { id }) {
  return await stelace.forward.post(`integrations/affinda/parseresume/${id}`)
}

// Trigger Affinda parsing process
export async function affindaParseProcess (context, { assetId = null, userId = null, payload = {}, standalone = false, s3FullPath = null }) {
  return await stelace.forward.post('/integrations/affinda/parseprocess', { assetId, userId, payload, standalone, s3FullPath })
}

// Allow fetching all types of resources on one or multiple users
export async function getUsersElements (context, { id, fields = ['stats'], strict = false, allFields = false }) {
  try {
    if (!id) return null
    return await await stelace.forward.get(`users/elements?id=${id}`, { fields, strict, allFields })
  } catch (e) { this.useLogger(e) }
}

// OLD WAY

export async function countUsers (context) {
  const client = await stelace.users.list({ role: 'client', nbResultsPerPage: 1 })
  const phenix = await stelace.users.list({ role: 'phenix', nbResultsPerPage: 1 })
  const dragon = await stelace.users.list({ role: 'dragon', nbResultsPerPage: 1 })
  const starter = await stelace.users.list({ role: 'starter', nbResultsPerPage: 1 })
  const essential = await stelace.users.list({ role: 'essential', nbResultsPerPage: 1 })
  const premium = await stelace.users.list({ role: 'premium', nbResultsPerPage: 1 })
  const applicant = await stelace.users.list({ role: 'applicant', nbResultsPerPage: 1 })
  const lead = await stelace.users.list({ role: 'lead', nbResultsPerPage: 1 })
  const happycab = await stelace.users.list({ userOrganizationId: 'org_Gw8IMgE1qff1lTEpMqff', nbResultsPerPage: 1 })
  return {
    clients: {
      label: 'Clients Étincelle',
      to: { name: 'users', params: { collection: 'clients' } },
      count: client.paginationMeta.nbResults - (phenix.paginationMeta.nbResults + dragon.paginationMeta.nbResults + starter.paginationMeta.nbResults + essential.paginationMeta.nbResults + essential.paginationMeta.nbResults)
    },
    phenix: {
      label: 'Clients Phénix',
      to: { name: 'users', params: { collection: 'clients' } },
      count: phenix.paginationMeta.nbResults
    },
    dragon: {
      label: 'Clients Dragon',
      to: { name: 'users', params: { collection: 'clients' } },
      count: dragon.paginationMeta.nbResults
    },
    starter: {
      label: 'Clients Starter',
      to: { name: 'users', params: { collection: 'clients' } },
      count: starter.paginationMeta.nbResults
    },
    essential: {
      label: 'Clients Essential',
      to: { name: 'users', params: { collection: 'clients' } },
      count: essential.paginationMeta.nbResults
    },
    premium: {
      label: 'Clients Premium',
      to: { name: 'users', params: { collection: 'clients' } },
      count: premium.paginationMeta.nbResults
    },
    applicant: {
      label: 'Candidats',
      to: { name: 'users', params: { collection: 'applicants' } },
      count: applicant.paginationMeta.nbResults
    },
    lead: {
      label: 'Leads',
      to: { name: 'users', params: { collection: 'leads' } },
      count: lead.paginationMeta.nbResults
    },
    happycab: {
      label: 'Team HappyCab',
      to: { name: 'users', params: { collection: 'happycab' } },
      count: happycab.paginationMeta.nbResults
    },
  }
}

// export async function buildOrganizationTree ({commit}, userId) {
//   var tree = await fetchCurrentUserWithOrganizations(userId)
//   return tree
// }

export async function buildOrganizationTree ({ commit, rootGetters }, userId) {
  const naturalUser = await stelace.users.read(userId)

  const organizationsIds = Object.keys(naturalUser.organizations)

  const promises = organizationsIds.map(organizationId => {
    return stelace.users.read(organizationId)
  })

  const organizations = await Promise.all(promises)

  console.log(organizations)
  const ownerIds = map(organizations, 'id')
  const assets = await stelace.assets.list({ ownerId: ownerIds })
  const users = await stelace.users.list({ userOrganizationId: ownerIds })
  for (const org of organizations) {
    org.users = []
    if (org.metadata.type === 'implantation') org.offers = []
    for (const asset of assets) {
      if (asset.assetTypeId === rootGetters['nav/brandTypeId'] && org.id === asset.ownerId) org.asset = asset
      if (asset.assetTypeId === rootGetters['nav/companyTypeId'] && org.id === asset.ownerId) org.company = asset
      if (asset.assetTypeId === rootGetters['nav/offerTypeId'] && org.id === asset.ownerId) org.offers.push(asset)
    }
    for (const user of users) {
      if (user.organizations[org.id]) org.users.push(user)
    }
  }
  return {
    naturalUser,
    organizations: sortBy(organizations, org => org.createdDate),
    users: sortBy(users, user => user.createdDate)
  }
}

export async function fetchAllUsers ({ commit }, ids) {
  const fetchUsers = (...args) => stelace.forward.get('users/advanced', ...args)
  const res = await fetchAllResultsAdvanced(fetchUsers, {
    id: ids
  })
  commit('setAllClients', res)
  return res
}

export async function fetchAllOrganizations ({ commit }, ids) {
  const fetchUsers = (...args) => stelace.users.list(...args)
  const res = await fetchAllResults(fetchUsers, {
    id: ids,
    type: 'organization'
  })
  commit('setAllOrgs', res)
  return res
}

export async function searchAllClients ({ commit }, query) {
  const fetchUsers = (...args) => stelace.users.list(...args)
  const res = await fetchAllResults(fetchUsers, {
    role: 'client',
    query
  })
  return res
}

export async function searchAllApplicants ({ commit }, query) {
  const fetchUsers = (...args) => stelace.users.list(...args)
  const res = await fetchAllResults(fetchUsers, {
    role: 'applicant',
    query
  })
  return res
}

export async function findWithDates ({ commit }, { createdDateGte, createdDateLte, role }) {
  const fetchTransactions = (...args) => stelace.forward.get('users/advanced', ...args)
  const res = await fetchAllResultsAdvanced(fetchTransactions, {
    'createdDate[gte]': createdDateGte,
    'createdDate[lte]': createdDateLte,
    role
  })
  return res
}

export async function fetchUserEntities ({ commit }, { id, fields = ['companies'], strict = false }) {
  const res = await stelace.forward.get('users/' + id + '/entities', {
    fields,
    strict
  })
  return res
}

export async function fetchUsersEntities ({ commit }, { ids, fields = ['companies'] }) {
  const res = await stelace.forward.get('users/entities', {
    fields,
    ids
  })
  return res
}

export async function fetchAllClients ({ commit, dispatch }, ids) {
  const fetchUsers = (...args) => stelace.forward.get('users/advanced', ...args)
  const res = await fetchAllResultsAdvanced(fetchUsers, {
    // role: 'client',
    id: ids
  })
  const entities = await dispatch('fetchUsersEntities', {
    ids,
    fields: ['companies', 'implantations', 'users', 'offers', 'applications', 'ratings', 'templates', 'messages']
  })
  for (const user of res) {
    if (entities && entities[user.id]) user.entities = _.clone(entities[user.id])
    if (user.entities && user.entities.implantations.length > 0) {
      for (const org of user.entities.implantations) org.company = _.get(_.filter(user.entities.companies, (c) => c.ownerId === org.id), '[0]')
      for (const offer of user.entities.offers) offer.company = _.get(_.filter(user.entities.companies, (c) => c.ownerId === offer.ownerId), '[0]')
    }
    if (!user.roles.includes('client')) {
      for (const u of user.entities.users) {
        if (u.roles.includes('client')) user.roles = user.roles.concat(u.roles)
      }
    }
  }
  commit('setAllClients', res)
  return res
}

export async function fetchAllDragons ({ commit }) {
  const fetchUsers = (...args) => stelace.users.list(...args)
  const res = await fetchAllResults(fetchUsers, {
    role: 'dragon'
  })
  commit('setAllDragons', res)
  return res
}

export async function fetchAllApplicants ({ commit }, ids) {
  const fetchUsers = (...args) => stelace.users.list(...args)

  let users = []
  const usersIds = uniq(ids)
  const chunksIds = chunk(usersIds, 100)
  // console.log(chunksIds)
  for (const chunkIds of chunksIds) {
    const res = await fetchAllResults(fetchUsers, {
      role: 'applicant',
      id: chunkIds
    })
    users = users.concat(res)
  }
  // const res = await fetchAllResults(fetchUsers, {
  //   role: 'applicant',
  //   id: ids
  // })
  await commit('setAllApplicants', users)
  return users
}

export async function listApplicant ({ commit }, { query, pagination }) {
  const order = !pagination.descending ? 'asc' : 'desc'
  if (query === '') query = undefined
  const res = await stelace.users.list({
    nbResultsPerPage: pagination.rowsPerPage,
    page: pagination.page,
    order,
    orderBy: pagination.sortBy,
    role: 'applicant',
    query
  })
  commit('listApplicant', res)
  return res
}

export async function listClient ({ commit }, { query, id, pagination }) {
  const order = !pagination.descending ? 'asc' : 'desc'
  if (query === '') query = undefined
  if (id === '') id = undefined
  const res = await stelace.users.list({
    nbResultsPerPage: pagination.rowsPerPage,
    page: pagination.page,
    order,
    id,
    orderBy: pagination.sortBy,
    role: 'client',
    query
  })
  commit('listClient', res)
  return res
}

export async function listLead ({ commit }, { query, pagination }) {
  const order = !pagination.descending ? 'asc' : 'desc'
  if (query === '') query = undefined
  const res = await stelace.users.list({
    nbResultsPerPage: pagination.rowsPerPage,
    page: pagination.page,
    order,
    orderBy: pagination.sortBy,
    role: 'lead',
    query
  })
  commit('listLead', res)
  return res
}

export async function listOrganizations ({ commit }, { query, pagination }) {
  const order = !pagination.descending ? 'asc' : 'desc'
  if (query === '') query = undefined
  const res = await stelace.users.list({
    nbResultsPerPage: pagination.rowsPerPage,
    page: pagination.page,
    type: 'organization',
    order,
    orderBy: pagination.sortBy,
    query
  })
  commit('listOrganizations', res)
  return res
}

export async function getOrganizations ({ commit }, orgIds) {
  if (!orgIds || orgIds === '' || orgIds.length === 0 || orgIds === undefined) return []
  const res = await stelace.users.list({
    nbResultsPerPage: 100,
    page: 1,
    id: orgIds,
    type: 'organization'
  })
  return res
}

export async function listHappycabTeam ({ commit }, { query, pagination, role }) {
  const order = !pagination.descending ? 'asc' : 'desc'
  if (query === '') query = undefined
  const res = await stelace.users.list({
    nbResultsPerPage: pagination.rowsPerPage,
    page: pagination.page,
    order,
    orderBy: pagination.sortBy,
    role,
    userOrganizationId: 'org_Gw8IMgE1qff1lTEpMqff',
    query
  })
  commit('listHappycabTeam', res)
  return res
}

export async function listAuthors ({ commit }) {
  const res = await stelace.users.list({
    nbResultsPerPage: 100,
    page: 1,
    order: 'desc',
    orderBy: 'createdDate',
    userOrganizationId: 'org_Gw8IMgE1qff1lTEpMqff',
  })
  const authors = []
  for (const user of res) {
    if (user.roles.includes('author')) authors.push(user)
  }
  console.log(authors)
  commit('listAuthors', authors)
  return res
}

export async function stats ({ commit, state }) {
  const stats = []
  for (const role of state.roles) {
    const res = await stelace.users.list({ roles: role.id })
    stats.push({
      type: role.name,
      count: res.paginationMeta.nbResults
    })
  }
  commit('stats', stats)
  return stats
}

export async function listRoles ({ commit }) {
  const res = await stelace.roles.list()
  console.log(res)
  commit('listRoles', res)
  return res
}

export async function listProdRoles ({ commit }) {
  const axios = require('axios')
  const prodInstance = axios.create({
    baseURL: 'https://api.happycab.fr',
    headers: {
      'x-api-key': process.env.STELACE_SECRET_API_KEY
    }
  })
  const res = await prodInstance.get('/roles')
  console.log(res)
  return res.data
}

export async function getUser ({ commit }, id) {
  const res = await stelace.users.read(id)
  return res
}

// export async function get ({ commit }, id) {
//   const res = await stelace.users.read(id)
//   commit('set', res)
//   return res
// }

export async function find ({ commit }, { query, platformData, pagination }) {
  const order = !pagination.descending ? 'asc' : 'desc'
  if (query === '') query = undefined
  console.log('find user')
  const res = await this._vm.$stelaxios.get('/users/advanced', {
    params: {
      nbResultsPerPage: pagination.rowsPerPage,
      page: pagination.page,
      order,
      orderBy: pagination.sortBy,
      platformData,
      query
    }
  })
  return res.data
}

export async function addOrg ({ commit }, object) {
  object.type = 'organization'
  const res = await stelace.users.create(object)
  return res
}

export async function transferOwnership ({ commit }, object) {
  const res = await stelace.forward.post('/auth/advanced/ownership', object)
  return res
}

export async function checkAvailability ({ commit }, username) {
  const avail = await stelace.users.checkAvailability({ username })
  return avail
}

export async function add ({ commit }, object) {
  if (object.username === undefined && object.type !== 'organization') object.username = object.email
  const res = await stelace.users.create(object)
  commit('set', res)
  return res
}
export async function addToOrganization ({ commit }, { userId, organizationId, roles = [] }) {
  const res = await stelace.users.joinOrganizationOrUpdateRights(userId, organizationId, roles)
  return res
}

export async function updateAssetOwner ({ commit }, { ownerId, assetId }) {
  const res = await this._vm.$stelaxios.post('/assets/advanced/update/owner', {
    assetId,
    ownerId
  })
  return res
}

// export async function remove ({ commit, dispatch }, userId) {
//   const transactions = await dispatch('transaction/list', { userId }, { root: true })
//   console.log(transactions.length)
//   for (const transaction of transactions) {
//     if (transaction.ownerId === userId || transaction.takerId === userId) await dispatch('transaction/remove', transaction.id, { root: true })
//   }
//   const assets = await stelace.assets.list({ ownerId: userId })
//   for (const asset of assets) {
//     if (asset.ownerId === userId) await stelace.assets.remove(asset.id)
//   }
//   const messages = await dispatch('message/list', { userId }, { root: true })
//   for (const message of messages) {
//     if (message.senderId === userId || message.receiverId === userId) await stelace.messages.remove(message.id)
//   }
//   const res = await stelace.users.remove(userId)
//   commit('set', null)
//   return res
// }

export async function edit ({ commit }, object) {
  const res = await stelace.users.update(object.id, object.data)
  commit('set', res)
  return res
}
