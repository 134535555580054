<template>
  <div>
    <div
      :id="containerID"
      ref="JSONEditor"
      class="JSONEditorContainer"
    />
    <div
      v-if="mode === 'save'"
      class="flex flex-center"
    >
      <q-btn
        v-if="!readonly"
        class="q-mt-lg q-pa-sm"
        dense
        rounded
        color="primary"
        icon="fa fa-save"
        @click="saveJSON"
      />
    </div>
  </div>
</template>

<script>
let JSONEditor = null
if (process.env.CLIENT) {
  JSONEditor = require('jsoneditor')
}
export default {
  props: {
    mode: {
      default: 'save'
    },
    fieldKey: {
      default: null
    },
    jsonData: {
      default: null
    },
    editAction: {
      default: null
    },
    readonly: {
      default: false
    },
    containerID: {
      default: 'JSONEditorContainer'
    }
  },
  emits: ['json-updated'],
  data () {
    return {
      editor: null
    }
  },
  mounted () {
    if (JSONEditor !== null && this.JSONData !== null) {
      const container = document.querySelector('#' + this.containerID)
      const options = {
        onChange: async (val) => {
          await this.emitJSON()
        }
      }
      this.editor = new JSONEditor(container, options)
      this.editor.set(this.JSONData)
      this.editor.expandAll()
    }
  },
  methods: {
    async emitJSON () {
      const json = this.editor.get()
      await this.$emit('json-updated', json, this.fieldKey)
    },
    async saveJSON () {
      try {
        const json = this.editor.get()
        const id = json.id

        // Cleaning entry
        delete json.ownerId
        delete json.id
        delete json.createdDate
        delete json.updatedDate
        delete json.livemode
        delete json.username
        delete json.firstname
        delete json.lastname
        delete json.notifyUrl
        delete json.stats
        delete json.logs
        if (json.currency === null) delete json.currency

        await this.$store.dispatch(this.editAction, { data: json, id })
        this.$q.notify({
          message: 'Entry updated.',
          color: 'positive'
        })
      } catch (e) {
        this.useLogger(e)
      }
    }
  }
}
</script>

<style src="jsoneditor/dist/jsoneditor.min.css"></style>
