export function headerTitle (state, headerTitle) {
  state.headerTitle = headerTitle
}

export function setConfig (state, config) {
  state.config = config
}

export function setKeys (state, apiKeys) {
  state.apiKeys = apiKeys
}

export function loading (state, loading) {
  state.loading = loading
}
