import { get } from 'lodash'
import stelace from 'hc-core/composables/stelace.js'
import { pickFirstKey } from 'hc-core/composables/misc.js'

// Self reinterpretation of a general logger
export function useLogger (error, message = undefined) {
  if (error && process.env.CLIENT && process.env.NODE_ENV === 'development') {
    console.error({
      level: 'error', // fatal, error, warn, info, debug
      timestamp: new Date().toISOString(),
      origin: `[${process.env.HC_ENV}_${process.env.HC_INSTANCE}_${process.env.HC_PLATFORM}]`,
      userAgent: window.navigator.userAgent,
      error
    })
  }
  this.notifyError(message)
}

// Custom analytics system
const ANALYTICS_ROOT_PATH = 'state.content.analytics'

// List all possibilities that you want to log, based on router/routes.js
export async function analyticsSlugExtractor ({ store = null, to = null }) {
  if (!store || !to || typeof to !== 'object') return
  const slug = pickFirstKey(to, [
    'params.assetSlug', // Offer
    'params.slug', // Company, Article
  ], null)
  if (slug) await store.commit('content/setAnalyticsSlug', slug)
}

export async function analyticsSender ({ store }) {
  if (!store) return
  const activity = get(store, ANALYTICS_ROOT_PATH, {})
  if (activity && Object.keys(activity).length > 0) {
    try {
      await stelace.forward.post('analytics/log', { ...activity })
    } catch (e) {}
    await store.commit('content/setEntries', { key: 'analytics', arr: {} })
  }
}
