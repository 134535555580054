import { LocalStorage } from 'quasar'

export function set (state) {
  const auth = LocalStorage.getItem('auth')
  if (auth !== null && auth !== undefined && !state.loggedIn) {
    state.user = auth.user
    state.userId = auth.userId
    state.token = auth.token
    state.refreshToken = auth.refreshToken
    state.loggedIn = auth.loggedIn
  }
}

export function setUser (state, user) {
  state.user = user
  LocalStorage.set('auth', state)
}

export function setToken (state, res) {
  state.token = res.accessToken
  state.refreshToken = res.refreshToken
  state.userId = res.userId
  state.loggedIn = true
  LocalStorage.set('auth', state)
}

export function logout (state) {
  state.user = null
  state.token = null
  state.loggedIn = false
  LocalStorage.remove('auth')
}
