export function isLoggedIn (state) {
  return state.loggedIn
}
export function current (state) {
  return state.user
}
export function currentUser (state) {
  return state.user
}

export function isSuperAdmin (state) {
  if (state.user && (state.user.roles.includes('dev'))) return true
  return false
}

export function isAdmin (state) {
  if (state.user && (state.user.roles.includes('admin') || state.user.roles.includes('dev'))) return true
  return false
}

export function isManager (state) {
  if (state.user && (state.user.roles.includes('admin') || state.user.roles.includes('manager') || state.user.roles.includes('dev'))) return true
  return false
}
