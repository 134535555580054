import { get, omit } from 'lodash'
import stelace from 'hc-core/composables/stelace'

export async function list ({ commit }, { eventType = undefined }) {
  let res = await stelace.tasks.list()
  commit('list', res)
  // Dirty way but we do it for HCTable, since stelace package remap it
  // Remove after updating API
  res = res.filter(r => {
    if (eventType && eventType !== r.eventType) return false
    return true
  })
  return {
    results: Object.values(omit(res, ['paginationMeta', 'lastResponse'])),
    page: get(res, 'paginationMeta.page', 0),
    nbPages: get(res, 'paginationMeta.nbPages', 0),
    nbResults: get(res, 'paginationMeta.nbResults', 0),
    nbResultsPerPage: get(res, 'paginationMeta.nbResultsPerPage', 0),
  }
}

export async function update (context, { id, attrs }) {
  return await stelace.tasks.update(id, attrs)
}

// Old way
export async function read (context, { id }) {
  return await stelace.tasks.read(id)
}

export async function add ({ commit }, object) {
  const res = await stelace.tasks.create(object)
  commit('set', res)
  return res
}

export async function remove ({ commit }, id) {
  const res = await stelace.tasks.remove(id)
  commit('set', null)
  return res
}

export async function edit ({ commit }, object) {
  const res = await stelace.tasks.update(object.id, object.data)
  commit('set', res)
  return res
}
