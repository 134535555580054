import stelace from 'hc-core/composables/stelace'

export async function list ({ commit }, { label = undefined, assetId = undefined, authorId = undefined }) {
  // const res = await stelace.forward.get('/templates', {
  //   label: label,
  //   assetId: assetId
  // })
  const res = await stelace.templates.list({
    label,
    assetId,
    authorId
  })
  commit('list', res)
  return res
}

export async function getTemplates ({ commit }, { label = undefined, assetId = undefined, authorId = undefined }) {
  const res = await stelace.documents.list({
    label,
    assetId,
    type: 'template',
    authorId
  })
  return res
}

export async function get ({ commit }, id) {
  // const res = await stelace.forward.get('/templates/'+id,)
  const res = await stelace.templates.read(id)
  commit('set', res)
  return res
}

export async function add ({ commit }, object) {
  // const res = await stelace.forward.post('/templates', object)
  const res = await stelace.templates.create(object)
  commit('set', res)
  return res
}

export async function remove ({ commit }, id) {
  const res = await stelace.templates.remove(id)
  commit('set', null)
  return res
}

export async function edit ({ commit }, object) {
  const res = await stelace.templates.update(object.id, object.data)
  commit('set', res)
  return res
}
