import _ from 'lodash'

// WIP : flex way
export function setTransactions (state, { key, arr = [] }) {
  _.set(state, key, arr)
}

export function list (state, list) {
  state.list = list
}

export function set (state, object) {
  state.current = object
}

export function setPagination (state, pagination) {
  state.pagination = pagination
}

export function setOwner (state, owner) {
  state.owner = owner
}

export function setTaker (state, taker) {
  state.taker = taker
}

export function setAllApplications (state, applications) {
  const applicationsById = {}
  for (const application of applications) {
    applicationsById[application.id] = application
  }
  state.applicationsById = applicationsById
  state.applications = applications
}
