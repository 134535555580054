import { orderBy, groupBy } from 'lodash'

export function list (state, list) {
  state.list = list
}

export function set (state, object) {
  state.current = object
}

export function setPagination (state, pagination) {
  state.pagination = pagination
}

export function setConversation (state, conversation) {
  state.conversation = conversation
}
export function setEmails (state, emails) {
  state.emails = emails
}
export function setConversations (state, messages) {
  state.messages = messages
  state.conversations = groupBy(orderBy(messages, ['createdDate'], ['desc']), 'topicId')
}

export function setInbox (state, inbox) {
  state.inbox = inbox
}
