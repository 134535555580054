<template>
  <QCard ref="AccountCard" :bordered="bordered" class="br-25" flat>
    <QCardSection :class="['bg-grey-1 text-primary', headerClass]">
      <div class="flex justify-between items-center relative-position overflow-hidden">
        <div class="flex items-center no-wrap">
          <slot name="header-left">
            <QBtn v-if="route" flat size="md" color="primary" dense class="q-mr-md" :icon="icon" :to="route" />
            <QIcon v-else size="26px" class="q-mr-md text-primary" :name="icon" />
            <component :is="route ? 'router-link' : 'div'" dense class="hc-link text-h6 text-weight-bold text-dark" :to="route">
              {{ computedTitle }}
            </component>
          </slot>
        </div>
        <div class="absolute" style="right:0;">
          <transition enter-active-class="animated fadeInRight">
            <QChip v-if="signal" icon-right="uil:save" square class="q-ma-none" size="13px" color="positive" text-color="white" label="Enregistré" />
          </transition>
          <slot v-if="!signal" name="header-right" />
        </div>
      </div>
    </QCardSection>
    <QSeparator v-if="separator" />
    <slot />
    <HCLoading :showing="loading" inner />
  </QCard>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'uil:info'
    },
    signal: {
      type: Boolean,
      default: false
    },
    separator: {
      type: Boolean,
      default: true
    },
    route: {
      type: Object,
      default: null
    },
    headerClass: {
      type: String,
      default: null
    },
    bordered: {
      type: Boolean,
      default: true,
    },
    path: {
      type: String,
      required: false, // Make it required once all transition is done
      default: null
    },
    loading: { // use to show loading on the full card
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedTitle () {
      if (this.path && this.translationExists(this.path)) return this.$t({ id: this.path })
      return this.title ? this.title : ''
    },
  }
}
</script>
