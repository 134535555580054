import _ from 'lodash'

// WIP : flex way
export function setUsers (state, { key, arr = [] }) {
  _.set(state, key, arr)
}

export function list (state, list) {
  state.list = list
}

export function listApplicant (state, applicants) {
  state.applicants = applicants
}

export function listClient (state, clients) {
  state.clients = clients
}

export function listLead (state, leads) {
  state.leads = leads
}

export function setAllClients (state, clients) {
  const clientsById = {}
  for (const client of clients) {
    clientsById[client.id] = client
  }
  state.clientsById = clientsById
  state.clients = clients
}

export function setAllOrgs (state, organizations) {
  const organizationsById = {}
  for (const organization of organizations) {
    organizationsById[organization.id] = organization
  }
  state.organizationsById = organizationsById
  state.organizations = organizations
}

export function setAllDragons (state, dragons) {
  const dragonsById = {}
  for (const dragon of dragons) {
    dragonsById[dragon.id] = dragon
  }
  state.dragonsById = dragonsById
  state.dragons = dragons
}

export function setAllApplicants (state, applicants) {
  const applicantsById = {}
  for (const applicant of applicants) {
    applicantsById[applicant.id] = applicant
  }
  state.applicantsById = applicantsById
  state.applicants = applicants
}

export function listHappycabTeam (state, happycabTeam) {
  state.happycabTeam = happycabTeam
}

export function listOrganizations (state, organizations) {
  state.organizations = organizations
}
export function listAuthors (state, authors) {
  state.authors = authors
}

export function listRoles (state, roles) {
  state.roles = roles
}

export function set (state, object) {
  state.current = object
}

export function setPagination (state, pagination) {
  state.pagination = pagination
}
