export default function () {
  return {
    list: [],
    applicants: [],
    applicantsById: [],
    happycabTeam: [],
    authors: [],
    clients: [],
    clientsById: {},
    organizations: [],
    organizationsById: {},
    dragons: [],
    dragonsById: {},
    leads: [],
    current: null,
    pagination: null,
    roles: []
  }
}
