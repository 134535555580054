import { set } from 'lodash'
import { mergeEntries } from 'hc-core/composables/content'

// Start of intl
export async function seti18nContent (state, { content }) {
  state.i18nContent = mergeEntries({ localEntries: content })
}

export function setLocale (state, { locale }) {
  state.locale = locale
}
// End of intl

export function list (state, list) {
  state.list = list
}

// export function set (state, object) {
//   state.current = object
// }

export function setPagination (state, pagination) {
  state.pagination = pagination
}

export function setPages (state, pages) {
  state.pages = pages
}

export function setSEOs (state, seos) {
  state.seos = seos
}

export function setBlog (state, blog) {
  state.blog = blog.results
}

export function setVdcBlog (state, blog) {
  state.vdcBlog = blog.results
}

export function setVdcCapsules (state, capsules) {
  state.vdcCapsules = capsules.results
}

export function setForms (state, forms) {
  state.forms = forms
}

export function setEmails (state, emails) {
  state.emails = emails
}

export function setPage (state, page) {
  state.page = page
}

// WIP : flex way
export function setEntries (state, { key, arr = [] }) {
  set(state, key, arr)
}
