// Mixin specific for this project
// For always loaded mixins, refer to mixins/global

import { cc } from 'hc-core/composables/misc'
import AWSMixins, { getS3SignedUrl, cleanPrefix } from 'hc-core/mixins/aws.js'

export default {
  mixins: [AWSMixins],
  data () {
    return {
      toolbar: [
        ['bold', 'italic', 'strike', 'underline', 'subscript', 'superscript'],
        ['token', 'hr', 'link', 'custom_btn'],
        ['print', 'fullscreen'],
        [
          {
            label: 'Font size',
            icon: 'text',
            fixedLabel: true,
            fixedIcon: true,
            list: 'no-icons',
            options: ['size-1', 'size-2', 'size-3', 'size-4', 'size-5', 'size-6', 'size-7']
          }
        ],
        ['quote', 'unordered', 'ordered', 'outdent', 'indent'],
        ['undo', 'redo'],
        ['viewsource']
      ]
    }
  },
  computed: {
    isAdmin () { return this.$store.getters['auth/isAdmin'] },
    isSuperAdmin () { return this.$store.getters['auth/isSuperAdmin'] },
  },
  methods: {
    cc,
    cleanPrefix,
    getS3SignedUrl,

    // TODO : consider moing it to mixins/aws
    getKeyImageUrl (filename) {
      return process.env.S3_IMAGE_HANDLER + filename
    },
    getKeyFileUrl (filename) {
      return process.env.S3_BASE_URL + filename
    },

    async logout () {
      await this.$store.commit('auth/logout')
      this.$router.push('/')
    },

    // Make it available everywhere so simpler
    async loginAs (user, onDev = false) {
      const res = await this.$store.dispatch('auth/loginAs', user.id)
      window.open(
        `${onDev ? 'http://localhost:8085' : 'https://app.happycab.fr'}/auth/${user.id}?token=${res.accessToken}`,
        '_blank' // <- This is what makes it open in a new window.
      )
    },
  }
}
