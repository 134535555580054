import stelace from 'hc-core/composables/stelace'

export async function list ({ commit }, { label = undefined, assetId = undefined }) {
  const res = await stelace.ratings.list({
    label,
    assetId
  })
  commit('list', res)
  return res
}

export async function get ({ commit }, id) {
  const res = await stelace.ratings.read(id)
  commit('set', res)
  return res
}

export async function getTestimonials ({ commit }, { authorId }) {
  const res = await stelace.ratings.list({
    authorId,
    label: 'testimonial'
  })
  return res
}

export async function add ({ commit }, object) {
  const res = await stelace.ratings.create(object)
  commit('set', res)
  return res
}

export async function remove ({ commit }, id) {
  const res = await stelace.ratings.remove(id)
  commit('set', null)
  return res
}

export async function edit ({ commit }, object) {
  const res = await stelace.ratings.update(object.id, object.data)
  commit('set', res)
  return res
}
