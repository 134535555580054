import stelace from 'hc-core/composables/stelace'

export async function getConfig ({ commit, dispatch, state }) {
  const res = await stelace.config.read()
  if (res.theme === null) res.theme = {}
  if (res.custom === null) res.custom = {}
  commit('setConfig', res)
  return res
}

export async function getIconFromDomain ({ commit }, domain) {
  const res = await this._vm.$axios.get('https://besticon-demo.herokuapp.com/allicons.json?url=' + domain)
  return res
}

export async function getKeys ({ commit }) {
  const res = await stelace.apiKeys.list()
  commit('setKeys', res)
  return res
}

export async function setConfig ({ commit, state }, config) {
  const res = await stelace.config.update(config)
  commit('setConfig', res)
  return res
}

export async function getAnalyticsReport ({ commit, state }, reportRequests) {
  const response = await stelace.forward.post('/analytics/google/report', {
    reportRequests
  })
  return response
}

export async function geocode (context, query) {
  // Removed since algolia no longer used
  return null
}

// Removed since algolia no longer used
// function formatPlacesResult (result) {
//   let displayName

//   if (typeof result.locale_names[0] === 'string') {
//     displayName = result.locale_names[0]
//   } else if (typeof result.locale_names.default === 'object') {
//     displayName = result.locale_names.default[0]
//   }
//   const displayNamePart1 = (displayName.split(',')[0] || '').trim()
//   const displayNamePart2 = (displayName.split(',')[1] || '').trim()

//   const postcode = get(result, 'postcode[0]', '')
//   let shortDisplayName = (get(result, 'city[0]') || // TODO: move this string to i18n if it works properly
//     get(result, 'county[0]', `${displayNamePart1}${
//       displayNamePart2 ? ', ' + displayNamePart2 : ''
//     }`))

//   shortDisplayName += (postcode ? `, ${postcode}` : '')

//   return {
//     id: result.objectID,
//     latitude: parseFloat(result._geoloc.lat),
//     longitude: parseFloat(result._geoloc.lng),
//     shortDisplayName,
//     displayName,
//     postcode,
//     importance: result.importance,
//     address: {
//       city: get(result, 'city[0]'),
//       county: get(result, 'county[0]'),
//       postcode: get(result, 'postcode[0]'),
//       street: get(result, 'locale_names[0]'),
//       country: get(result, 'country')
//     }
//   }
// }
