import { createInstance } from 'stelace'
import { chunk, set, get } from 'lodash'

const apiBaseURL = process.env.STELACE_API_URL
const apiKey = process.env.STELACE_PUBLISHABLE_API_KEY
const MAX_RESULTS_PER_PAGE = 100 // cf. Stelace API reference for pagination details

if (!apiKey) throw new Error('Missing Stelace publishable API key')
const stelace = initStelaceSdk({ apiBaseURL, apiKey })

export default stelace

export { getStelaceEnv, fetchAllResults, fetchAllResultsChunks, fetchAllResultsAdvanced, setOwnerIdFilter }

function getStelaceEnv () {
  return apiKey.includes('_live_') ? 'live' : 'test'
}

export function initStelaceSdk ({ apiBaseURL, apiKey }) {
  const stelace = createInstance({ apiKey })

  if (apiBaseURL) {
    const parsedUrl = new URL(apiBaseURL)

    const host = parsedUrl.hostname
    const port = parsedUrl.port
    const protocol = parsedUrl.protocol.slice(0, -1)

    stelace.setHost(host, port, protocol)
  }

  return stelace
}

/**
 * Use this function to fetch all results, it will automatically go through the pagination results
 * @param {Function} fetchFn({ page, nbResultsPerPage, ...params }) - fetch results factory, should return a promise
 * @param {Object} [params]
 * @return {Object[]} allResults
 */
async function fetchAllResults (fetchFn, params = {}) {
  const nbResultsPerPage = MAX_RESULTS_PER_PAGE
  let page = 1
  let allResults = []
  let results

  do {
    const passedParameters = Object.assign({}, params, { page, nbResultsPerPage })
    results = await fetchFn(passedParameters)
    page += 1

    allResults = allResults.concat(results.results || results)
  } while (get(results, 'nbPages', get(results, 'paginationMeta.nbPages', 1)) > get(results, 'page', get(results, 'paginationMeta.page', 0)))

  return allResults
}

/**
 * Use this function to fetch all results, it will automatically go through the pagination results
 * @param {Function} fetchFn({ page, nbResultsPerPage, ...params }) - fetch results factory, should return a promise
 * @param {Object} [params]
 * @param {String} [chunkKey] key to split for chunks
 * @return {Object[]} allResults
 */
async function fetchAllResultsChunks ({ fetchFn, params = {}, chunkKey = 'id' }) {
  let results
  let allResults = []
  const chunks = chunk(params[chunkKey], 100)
  const passedParameters = Object.assign({}, params, { page: 1, nbResultsPerPage: MAX_RESULTS_PER_PAGE })
  for (let i = 0; i < chunks.length; i++) {
    set(passedParameters, chunkKey, chunks[i])
    results = await fetchFn(passedParameters)
    allResults = allResults.concat(results)
  }
  return allResults
}

// Same function as before but with 1000 results per page
async function fetchAllResultsAdvanced (fetchFn, params = {}) {
  const nbResultsPerPage = 1000
  let page = 1
  const allResults = []
  let results
  let res

  do {
    const passedParameters = Object.assign({}, params, { page, nbResultsPerPage })
    res = await fetchFn(passedParameters)
    results = res.results
    page += 1

    allResults.push(...results)
  } while (res.nbPages > res.page)

  return allResults
}

async function setOwnerIdFilter (ownerIds) {
  let filter = ''
  if (ownerIds) {
    for (let i = 0; i < ownerIds.length; i++) {
      if (i != ownerIds.length - 1) filter += `_ownerId == ${ownerIds[i]} OR ` // eslint-disable-line eqeqeq
      else filter += `_ownerId == ${ownerIds[i]}`
    }
  }
  if (filter === '') return undefined
  return filter
}
