export function assets (state) {
  return state.list
}
export function assetsCount (state) {
  return state.pagination
}
export function pagination (state) {
  return state.pagination
}
export function types (state) {
  return state.types
}
