export default function () {
  return {
    list: [],
    pagination: null,
    current: null,
    types: [],
    categories: [],
    attributes: [],
    vdcAnecdotes: [],
    assets: [],
    attribute: null,
    stats: [],
    companiesById: {},
    companiesByOwnerId: null,
    resumesById: {},
    resumesByOwnerId: {},
    offersById: {},
    offersByOwnerId: {},
    companies: [],
    offers: [],
    mapping: null
  }
}
